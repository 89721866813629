import React from 'react';
import './Indices.scss';
import IndicesListing from "./IndicesListing/IndicesListing";
import {useAppSelector} from "../../store/hooks";
import {selectedIndicesSelector} from "../../store/slice/indices/indices";
import SelectedIndices from "./SelectedIndices/SelectedIndices";

const Indices = () => {
  const selectedIndices = useAppSelector(selectedIndicesSelector);

  return (
    <>
      {selectedIndices.length === 0 ? <IndicesListing/> : <SelectedIndices/>}
    </>

  )
};

export default Indices;
