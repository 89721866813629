import React from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Metric, MetricHeader, MetricContent } from '@jpmuitk/metric';
import './Metrics.scss';
import {toSentenceCase} from "../../../utils/Utils";
export interface IMetricsData {
  header: string,
  column_1?: string | number,
  column_2?: string | number,
}
interface IMetricsProps {
  columns: number,
  metricsHeading?: string,
  metricsData: IMetricsData[],
  headerClass?: string,
  column_1_Class?: string,
  column_2_Class?: string,
  negativeClass?: string
}

const Metrics = (
  {
   columns,
   metricsHeading,
   metricsData,
   headerClass,
   column_1_Class,
   column_2_Class,
    negativeClass=''
  }: IMetricsProps) => {

  const addClasses = (val,header) => {
    let classesName = `${column_2_Class} ${!isNaN(val) && val < 0 ?`${'negative-number'+ negativeClass} focus-class` :
      isNaN(val) && val.indexOf('%')!== -1 &&  val.indexOf('-') === 0 ? `${'negative-number'+ negativeClass} focus-class` : 'focus-class' }`;
    if(header === "# of negative months") {
      classesName = `${column_2_Class} negative-number focus-class`;
    }
    return classesName;
  }

  const createMetrics = () => {
    return metricsData.map(value => {
      return (
        <div className="grid-item">
        <Metric  orientation="horizontal" className='test-metrics' >
          <MetricHeader title={toSentenceCase(value.header)} className={`${headerClass} focus-class`}  tabIndex={0}/>
          {value?.column_1 && columns === 2 ? <MetricContent value={toSentenceCase(value?.column_1)} className={column_1_Class}/> : null}
          {value?.column_2 ?
            <MetricContent
            value={value?.column_2}
            className={addClasses(value?.column_2, toSentenceCase(value.header))} tabIndex={columns === 2 ? 0 : -1}/> :
            null}
        </Metric>
        </div>
      )
    })
  }
  return (
    <ColumnLayout container className='metrics'>
      <div className="grid-container">
        {createMetrics()}
      </div>
    </ColumnLayout>
  );
};

export default Metrics;
