import React, {useMemo, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { DataGrid } from '@jpmuitk/data-grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {getMonthlyReturnColumnDefs} from "./MonthlyReturnColumnDefs/MonthlyReturnColumnDefs";
import './MonthlyReturn.scss';
import {useAppSelector} from "../../../../../store/hooks";
import {selectedIndicesSelector} from "../../../../../store/slice/indices/indices";
import moment from "moment";
interface IMonthlyReturnParams {
  monthlyReturnData: any[]
}
const MonthlyReturn = ({monthlyReturnData = []}:IMonthlyReturnParams) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const selectedIndicesData = useAppSelector(selectedIndicesSelector);
  const onGridReady = ({ api }) => {
    setTimeout(() => {
      api?.sizeColumnsToFit();
    });
    setGridApi(api);
    window.onresize = () => {
      if (api) {
        api.sizeColumnsToFit();
      }
    }
  };

  return (
    <>
      <ColumnLayout container spacing={3} className="monthly-return">
        <ColumnLayout item className="monthly-return-grid">
          <h3>Monthly Return (%)</h3>
          <div className="monthly-return-grid-container" style={{height: `${36 * monthlyReturnData.length + 36}px`}}>
            <DataGrid
              columnDefs={getMonthlyReturnColumnDefs(moment(selectedIndicesData[0]?.liveDate).get('year'), moment(selectedIndicesData[0]?.liveDate).get('month'))}
              rowData={monthlyReturnData}
              rowHeight={36}
              domLayout={'autoHeight'}
              onGridReady={onGridReady}
              getRowStyle={params => {
                if(params.data.year < moment(selectedIndicesData[0]?.liveDate).get('year')) {
                  return {'background-color': '#eae6e6'}
                }
                return null;
              }
              }
            />
          </div>
        </ColumnLayout>
      </ColumnLayout>
    </>
  )
};

export default MonthlyReturn;
