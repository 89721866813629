import moment, { Moment } from 'moment';

export const DD_MMM_YYYY = 'DD MMM YYYY';
export const DD_MM_YYYY = 'DD-MM-YYYY';

// Returns the years options for the yearDropdownRange of Date or DateRangePicker taking into account the year diff between start
// and current year. Defaults to 10 years;
export const getYearDropdownRange = (startDate: Moment): Array<{ value: number; text: number; id: number }> => {
  const yearDiff = startDate && moment().year() - startDate.year() + 1;
  const maxYears = yearDiff > 10 ? yearDiff : 10;
  return Array.from(Array(maxYears).keys()).map(i => {
    const year = moment().year() - i;
    return { value: year, text: year, id: i };
  });
};

// Returns the years options for the yearDropdownRange of Date or DateRangePicker taking into account the year diff between start
// and current year. Defaults to 10 years;

export const isWeekend = (date: Moment = moment()) => {
  const day = date.day();
  return day === 6 || day === 0;
};
