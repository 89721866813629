import React, {useState, Suspense, useEffect, useMemo} from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory
} from "react-router-dom";
import "./AppNav.scss";
import { ColumnLayout } from "@jpmuitk/column-layout";
import Indices from "./components/Indices/Indices";
import Notices from "./components/Notices/Notices";
import Loader from "./components/common/Loader/Loader";
import LandingHeader from "./components/LandingPage/LandingHeader";
import LandingPage from "./components/LandingPage/LandingPage";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {
  indexFromURLSelector,
  selectedIndicesSelector,
  selectedProfileSelector, setIndexFromURL, setSelectedIndices,
  setSelectedProfile,
  setShowLandingPage,
  showLandingPageSelector
} from "./store/slice/indices/indices";
import { AppHeaderNext } from '@jpmuitk/app-header';
import ExitProfile from "./components/ExitProfile/ExitProfile";
import Footer from "./components/common/Footer/Footer";
import {US_INDIVIDUAL,} from "./utils/TabsList";
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import AboutUs from "./components/AboutUs/AboutUs";
import { selectedTabSelector, setSelectedTab } from "./store/slice/indices/indices";
import {useCookies} from "react-cookie";
import {getNavigationSource} from "./utils/Utils";
import GlobalIndexSearch from "./components/common/IndicesSearch/GlobalIndexSearch";

const theme = createToolkitTheme('dark');
const AppNav = () => {
  const history = useHistory();
  const [viewIndex, setViewIndex] = useState(0);
  const [tabs, setTabs] = useState<string[]>([]);
  const showLanding = useAppSelector(showLandingPageSelector);
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const selectedTab = useAppSelector(selectedTabSelector);
  const selectedIndices = useAppSelector(selectedIndicesSelector);
  const [navigationSource, setNavigationSource] = useState(US_INDIVIDUAL);
  const [cookies, setCookies]  = useCookies(['selectedCountry', 'selectedProfile', 'selectedTab']);
  const actionSource = [
    { itemRenderer: () => GlobalIndexSearch() },
    { itemRenderer: () => ExitProfile() }
  ];
  const dispatch = useAppDispatch();
  const indexFromURL = useAppSelector(indexFromURLSelector);
  useEffect(() => {
    if (cookies?.selectedProfile?.length > 0 && cookies?.selectedTab?.length > 0 ) {
      dispatch(setSelectedProfile(cookies.selectedProfile));
      dispatch(setSelectedTab(cookies.selectedTab));
      const navSource = getNavigationSource(cookies.selectedProfile);
      navSource.map(val => {
        if(val.label === cookies.selectedTab) {
          setViewIndex(val.index);
        }
      });
    }
  }, []);
  const IndicesRoute = ({ children, ...rest }) => {
    return (
      <Route
        children={undefined} {...rest}
        render={() => {
          return children;
        }}    />
    );
  };
  const setRoute = object => {
    setViewIndex(object.index);
    if (object.label === 'About Us') {
      dispatch(setSelectedTab('About Us'));
    } else {
      dispatch(setSelectedTab(object.label));
    }
    if (object.label === 'Indices') {
      dispatch(setSelectedIndices([]));
      dispatch(setIndexFromURL(''));
    }
    history.push(`/${object.itemId}`);
  };
  const navigationProps = {
    currentIndex: viewIndex,
    onItemClick: setRoute
  };
  useMemo(() => {
    navigationSource.map(val => {
      if(val.label === selectedTab) {
        setViewIndex(val.index);
      }
    });
    let expires = new Date();
    expires.setTime(expires.getTime() + 10800000);
    setCookies('selectedTab', selectedTab, {path:"/", expires});
    if (selectedTab === 'About Us') {
      actionSource?.splice(0, 1);
    }
  }, [selectedTab, navigationSource]);
  useEffect(() => {
      setNavigationSource(getNavigationSource(selectedProfile));
      let expires = new Date();
      expires.setTime(expires.getTime() + 10800000);
      setCookies('selectedProfile', selectedProfile, {path:"/", expires});
  }, [selectedProfile]);
  useEffect(() => {
    const tabs: string[] = ["Indices", "Notices", "About Us"];
    setTabs(tabs);
  }, []);
  useEffect(() => {
    if (tabs?.length > 0) {
      setShowLandingPage(false);
    }
  },[tabs]);

useEffect(() => {
  if (history.action === 'POP' && history.location.pathname.split('/').pop() === 'indices') {
    dispatch(setSelectedIndices([]));
    dispatch(setIndexFromURL(''));
  }

  if(window.location.href.split('/').indexOf('indices') !== -1 && window.location.href.split('/').pop() !== 'indices') {
    const index= window.location.href.split('/').pop();
    dispatch(setIndexFromURL(index));
  }
  if(window.location.href.split('/').pop() === 'landing') {
    dispatch(setShowLandingPage(true));
    dispatch(setSelectedProfile(""));
    history.push('/landing');
    dispatch(setSelectedIndices([]));
  } else if(window.location.href.split('/').indexOf('indices') !== -1 && window.location.href.split('/').pop() === 'indices'  && indexFromURL === ''){
    dispatch(setSelectedIndices([]));
    dispatch(setSelectedTab('Indices'));
    dispatch(setIndexFromURL(''));
  } else if(window.location.href.split('/').indexOf('indices') !== -1 && window.location.href.split('/').pop() !== 'indices' ){
    if(selectedIndices.length === 0) {
      history.push('/indices');
    }
    dispatch(setSelectedTab('Indices'));
  } else if(window.location.href.split('/').pop() === 'notices'){
    dispatch(setSelectedTab('Notices'));
  } else if(window.location.href.split('/').pop() === 'aboutUs'){
    dispatch(setSelectedTab('About Us'));
  }

},[window.location.href, indexFromURL]);

useEffect(() => {
  document.title = selectedProfile === "" ? "J.P.Morgan Investable Indices Landing Page" : `J.P.Morgan Investable Indices ${selectedTab} Page`;
}, [selectedTab, selectedProfile]);

  const actionProps = {};
  return (
    <Suspense fallback={<Loader />}>
        <ColumnLayout container item id="app-nav" className="app-nav">
        <ColumnLayout item xs={12} className='landing-header-container'>
          <LandingHeader/>
        </ColumnLayout>
        {showLanding && selectedProfile === "" ?
          <ColumnLayout item xs={12}>
            <LandingPage/>
          </ColumnLayout>:
          <ThemeProvider theme={theme}>
          <ColumnLayout item xs={12} className="tabs-container">
            <div className="app-nav-wrapper">
              <div className="app-nav-tabs">
                <div className="app-nav-content">
                  {selectedProfile ? (
                          <div className="lef" style={{padding: '20px 50px'}}>
                            <AppHeaderNext
                              id="tabs-app-header"
                              actionSource={actionSource}
                              actionProps={actionProps}
                              navigationProps={navigationProps}
                              navigationSource={navigationSource}
                              logoProps={{className: 'app-header'}}
                            />
                          </div>
                  ) : null }
                </div>
              </div>
            </div>
          </ColumnLayout>
            <ColumnLayout container>
              <span className={"page-heading"} key={selectedTab}>{selectedIndices?.length === 0 ? selectedTab : selectedTab === 'About Us' ? 'About Us' : selectedTab === 'Notices' ? 'Notices' : selectedIndices[0].indexName}</span>
              <span className={"page-heading-index"}>{selectedIndices?.length === 0 ? '' : selectedTab === 'About Us' ? "" : selectedTab === 'Notices' ? "" : selectedIndices[0]?.ticker?.split(' ')[0]}</span>
            </ColumnLayout>
          </ThemeProvider>}


      </ColumnLayout>
      {selectedProfile ? <ColumnLayout container item id="app-content" className="flex-1">
        <Switch>
          <IndicesRoute exact path="/">
            <Redirect to="/landing"/>
          </IndicesRoute>
          <IndicesRoute exact path="/indices">
            {cookies.selectedProfile || selectedProfile.length > 0 ? <Indices/> : <Redirect to="/landing"/>}
          </IndicesRoute>
          <IndicesRoute exact path="/indices/*">
            {cookies.selectedProfile || selectedProfile.length > 0 ? <Indices/> : <Redirect to="/landing"/>}
          </IndicesRoute>
          <IndicesRoute exact path="/notices">
            <Notices/>
          </IndicesRoute>
          <IndicesRoute exact path="/aboutUs">
            <AboutUs/>
          </IndicesRoute>
          <IndicesRoute exact path="/landing">
            <LandingPage/>
          </IndicesRoute>
        </Switch>
      </ColumnLayout> : null}
      <ColumnLayout item xs={12}>
        <Footer/>
      </ColumnLayout>
    </Suspense>
  );
};

export default AppNav;
