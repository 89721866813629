import React from 'react';
interface IHtmlTextRendererComponent {
  htmlText: string,
  purify?:boolean
}
const HtmlTextRendererComponent = ({htmlText, purify=true}: IHtmlTextRendererComponent) => {
  const DOMPurify = require('dompurify')(window);
  return (
    <>
      {purify ? <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmlText)}}/> : <span dangerouslySetInnerHTML={{__html: htmlText}}/>}
    </>

  )
}
export default HtmlTextRendererComponent;
