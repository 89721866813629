
import {getColumnValue, getNumberStyle} from "../../../../../../utils/Utils";
const getMonthlyReturn = (data, header) => {
  let monthlyReturn = '';
  data.map(obj => {
    if(obj.month.toLowerCase() === header) {
      monthlyReturn = obj.value;
    }
  })
  return monthlyReturn;
}

export const getMonthlyReturnColumnDefs = (year, month) => {
  const MonthlyReturnColumnDefs = [
    {
      headerName: 'Year',
      headerClass: ['grid-header'],
      cellStyle: params => {
        if(params?.data?.year === year) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'year',
      maxWidth: 200
    },
    {
      headerName: 'Total',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year < year ;
        return [`${getNumberStyle( params?.data?.totalReturn, isDark)} bold-font`]
      },
      cellStyle: params => {
        if(params?.data?.year <= year) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'total',
      valueGetter: params => {
        return getColumnValue(params.data.totalReturn);
      },
      minWidth: 80
    },
    {
      headerName: 'Jan',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >=0;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'jan'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >=0 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'jan',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'jan'));
      },
      minWidth: 80
    },
    {
      headerName: 'Feb',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >=1;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'feb'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >=1 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'feb',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'feb'));
      },
      minWidth: 80
    },
    {
      headerName: 'Mar',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 2;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'mar'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 2 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'mar',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'mar'));
      },
      minWidth: 80
    },
    {
      headerName: 'Apr',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 3;
        return [getNumberStyle(getMonthlyReturn(params?.data?.monthlyReturn, 'apr'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 3 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'apr',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'apr'));
      },
      minWidth: 80
    },
    {
      headerName: 'May',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 4;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'may'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 4 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'may',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'may'));
      },
      minWidth: 80
    },
    {
      headerName: 'Jun',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 5;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'jun'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 5 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'jun',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'jun'));
      },
      minWidth: 80
    },
    {
      headerName: 'Jul',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 6;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'jul'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 6 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'jul',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'jul'));
      },
      minWidth: 80
    },
    {
      headerName: 'Aug',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 7;
        return [getNumberStyle(getMonthlyReturn(params?.data?.monthlyReturn, 'aug'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 7 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'aug',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'aug'));
      },
      minWidth: 80
    },
    {
      headerName: 'Sep',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 8;
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'sep'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 8 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'sep',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'sep'));
      },
      minWidth: 80
    },
    {
      headerName: 'Oct',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 9;
        return [getNumberStyle(getMonthlyReturn(params?.data?.monthlyReturn, 'oct'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 9 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'oct',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'oct'));
      },
      minWidth: 80
    },
    {
      headerName: 'Nov',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 10;
        return [getNumberStyle(getMonthlyReturn(params?.data?.monthlyReturn, 'nov'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 10 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'nov',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'nov'));
      },
      minWidth: 80
    },
    {
      headerName: 'Dec',
      headerClass: ['grid-header', 'number-cell-header'],
      cellClass: params => {
        const isDark = params?.data?.year <= year && month >= 11
        return [getNumberStyle( getMonthlyReturn(params?.data?.monthlyReturn, 'dec'), isDark)]
      },
      cellStyle: params => {
        if(params?.data?.year === year && month >= 11 ) {
          return {'background-color': '#eae6e6'}
        }
      },
      field: 'dec',
      valueGetter: params => {
        return getColumnValue(getMonthlyReturn(params?.data?.monthlyReturn, 'dec'));
      },
      minWidth: 80
    }
  ];

  return MonthlyReturnColumnDefs;
};
