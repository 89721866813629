import 'react-dates/lib/css/_datepicker.css';
import './DatePeriodPicker.scss';
import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { Button } from '@jpmuitk/button';
import { DateRangePicker, DateRangePickerProps } from '@jpmuitk/date-picker';
import DateRange from '../models/DateRange';
import DatePeriod, { datePeriodLabelMap } from '../models/DatePeriod';
import { getYearDropdownRange, isWeekend } from '../helpers/common/dateUtils';
import { CustomRenderPanelForDateRangePicker } from './CustomRenderPanel';
import { DateRangeState } from './useDateRangeState';

const getVariant = (selectedRange, range): any => {
  return selectedRange === range ? 'regular' : 'secondary';
};

interface DateSelectProps {
  selectedPeriod: DatePeriod;
  onPeriodChanged: (period: DatePeriod) => void;
  datesChange: (dateRange: DateRange) => void;
  startDate: Moment;
  endDate: Moment;
  baseDate: Moment;
  availableDates?: Moment[];
  indexHolidaysDates?: Moment[];
  disabled?: boolean;
  includePeriods?: string[];
  compact?: boolean;
  dateRangeState?: DateRangeState;
  dateRangePickerClassName?: string;
  dateFormat?:string;
}

export const DEFAULT_PERIODS = [DatePeriod.MTD, DatePeriod.M1, DatePeriod.M3, DatePeriod.YTD, DatePeriod.Y1];

const DatePeriodPicker = ({
  selectedPeriod,
  onPeriodChanged,
  datesChange,
  startDate,
  endDate,
  baseDate,
  availableDates,
  indexHolidaysDates,
  disabled,
  includePeriods = [...DEFAULT_PERIODS, DatePeriod.MAX],
  compact = false,
  dateRangeState,
  dateRangePickerClassName = 'date-range-picker',
  dateFormat = 'DD-MM-YYYY'
}: DateSelectProps) => {
  const [rangeFocus, onRangeFocusChange] = useState();

  const yearDropdownRange: any = getYearDropdownRange(baseDate);

  const availableDatesMap =
    availableDates &&
    availableDates.reduce((map, availableDate) => {
      map[availableDate.format('YYYY-MM-DD')] = true;
      return map;
    }, {});

  const firstAvailableDate = availableDates && availableDates.length > 0 && availableDates[0];
  const initialVisibleMonth = (rangeFocus === 'endDate' ? endDate : startDate) as any;

  const additionalProps: DateRangePickerProps = {};
  if (compact) {
    additionalProps.renderPanel = CustomRenderPanelForDateRangePicker(dateRangeState);
  }

  return (
    <div className="date-select">

      <div className={dateRangePickerClassName}>
        <DateRangePicker
          key={`date-range-picker-${startDate}-${endDate}`}
          focusedInput={rangeFocus}
          {...({ onRangeFocusChange } as any)}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          onDatesChange={datesChange}
          dateFormat={dateFormat}
          YearDropdownProps={{
            source: yearDropdownRange
          }}
          StartInputProps={{
            style: {
              width: 102
            }
          }}
          EndInputProps={{
            style: {
              width: 120,
              marginLeft: 4
            }
          }}
          CalendarProps={{
            isDayBlocked: (day: Moment) => {
              if (availableDates) {
                // @ts-ignore
                if (day.isSameOrAfter(firstAvailableDate, 'day')) {
                  // @ts-ignore
                  return !availableDatesMap[day.format('YYYY-MM-DD')];
                }
              } else {
                return false;
              }

              return isWeekend(day) || day.isBefore(baseDate) || day.isAfter(moment());
            }
          }}
          initialVisibleMonth={() => initialVisibleMonth}
          readOnly
          disabled={disabled}
          {...additionalProps}
        />
      </div>
      {!compact && (
        <div className="date-select-buttons" role="group">
          {Object.keys(DatePeriod).map(
            period =>
              includePeriods.includes(period) && (
                <Button
                  id={period}
                  key={period}
                  density="high"
                  variant={getVariant(selectedPeriod, period)}
                  onClick={() => onPeriodChanged(DatePeriod[period])}
                  disabled={disabled}
                  role="switch"
                  aria-checked={selectedPeriod === period}
                >
                  {datePeriodLabelMap[period]}
                </Button>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default DatePeriodPicker;
