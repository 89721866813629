import React, {useEffect, useState} from "react";
import { ColumnLayout } from '@jpmuitk/column-layout';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import 'react-dates/lib/css/_datepicker.css';
import { Panel } from '@jpmuitk/panel';
import { Metric, MetricHeader, MetricContent } from '@jpmuitk/metric'
const theme = createToolkitTheme('dark');
import './Reports.scss';
import {DataGrid} from "@jpmuitk/data-grid";
import { useQuery } from 'react-query';
import {getReportsQuery} from "../../../../utils/Utils";
import {useCookies} from "react-cookie";
import ReportsGridColumnDefs from "../../ColumnDefs/ReportsGridColumnDefs";
import GridSearch from "../../../common/GridSearch/GridSearch";
import moment from "moment";
import { FormField } from '@jpmuitk/form-field';
import { DateRangePicker } from '@jpmuitk/date-picker';
import 'react-dates/lib/css/_datepicker.css';
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {
  reportsEndDateSelector,
  reportsStartDateSelector,
  selectedIndicesSelector, selectedInternalTabSelector, selectedProfileSelector,
  setReportsEndDate,
  setReportsStartDate
} from "../../../../store/slice/indices/indices";
import HtmlTextRendererComponent from "../../../common/HtmlTextRendererComponent";
import {COMMON_FOOTNOTES, US_INDIVIDUAL_SUMMERY_FOOTNOTES} from "../../../../utils/TextContent";
import Footnotes from "../../Footnotes/Footnotes";
const Reports = () => {
  const dispatch = useAppDispatch();
  const selectedIndices = useAppSelector(selectedIndicesSelector)[0];
  const selectedInternalTab = useAppSelector(selectedInternalTabSelector);
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const indexId = selectedIndices?.ticker?.split(" ")[0];
  const reportsStartDate = useAppSelector(reportsStartDateSelector);
  const reportsEndDate = useAppSelector(reportsEndDateSelector);
  const reportsParams = `${indexId}?startDate=${reportsStartDate}&endDate=${reportsEndDate}`
  const [cookies]  = useCookies(['selectedProfile']);
  const [enabled, setEnabled] = useState(false);
  const {data, isFetching, refetch } = useQuery(
    ["Reports"],
    getReportsQuery(selectedProfile.length > 0 ? selectedProfile : cookies.selectedProfile, reportsParams),
    {enabled: enabled});
  const [gridApi, setGridApi] = useState<any>(null);
  const [reportsData, setReportsData] = useState([]);
  const [footnoteUpdatedData, setFootnoteUpdatedData] = useState<any[]>(US_INDIVIDUAL_SUMMERY_FOOTNOTES);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'years'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days'));
  const [startInputValue, setStartInputValue] = useState('');
  const [endInputValue, setEndInputValue] = useState('');
  useEffect(() => {
    const US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY = JSON.parse(JSON.stringify(US_INDIVIDUAL_SUMMERY_FOOTNOTES)) as typeof US_INDIVIDUAL_SUMMERY_FOOTNOTES;
    if (selectedIndices?.simulatedReturns) {
      US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY[0].description = `<h3>Use of Simulated Returns</h3><div>${selectedIndices?.simulatedReturns}</div> ${US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY[0].description}`;
    }
    const currentData = [...US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY];
    if (selectedIndices?.footnotes) {
      currentData[1].description=selectedIndices?.footnotes;
    } else {
      currentData.splice(1,1);
    }
    setFootnoteUpdatedData(currentData);
    gridApi?.sizeColumnsToFit();
  }, [selectedIndices, gridApi]);

  useEffect(() => {
    if(reportsStartDate !== "" && reportsEndDate !== "") {
      setEnabled(true);
    }
  },[reportsStartDate,reportsEndDate]);
  useEffect(() => {
    dispatch(setReportsStartDate(moment(startDate).format("YYYY-MM-DD")));
    dispatch(setReportsEndDate(moment(endDate).format("YYYY-MM-DD")));
  },[startDate,endDate]);
  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
    window.onresize = () => {
      if(api) {
        api.sizeColumnsToFit();
      }
    }
  };

  useEffect(() => {
    if(data) {
      setReportsData(data);
      setEnabled(false);
    }
  }, [data, isFetching]);
  const searchInGrid = (val, type) => {
    gridApi.setQuickFilter(val);
  }

  const handleDatesChange = ({startDate: newStartDate, endDate: newEndDate,}) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    dispatch(setReportsStartDate(moment(newStartDate).format("YYYY-MM-DD")));
    dispatch(setReportsEndDate(moment(newEndDate).format("YYYY-MM-DD")));
  }

  const handleInputValuesChange = ({startDate: newStartDate, endDate: newEndDate,}) => {
    setStartInputValue(newStartDate || '');
    setEndInputValue(newEndDate || '');
  };
  const cellClickHandler = (event) => {
    if (event?.colDef?.headerName === 'Action' && event.event.key === "Enter") {
      setTimeout(() => {
        window.open(`${window.location.origin}/indices/content/${selectedIndices[0]?.ticker?.split(' ')[0]}/${event?.data?.documentName}?region=${selectedProfile.split(' ').slice(0, -1).join('_').toUpperCase()}&clientType=${selectedProfile.split(' ').pop().toUpperCase()}&documentType=REPORT`, '_blank');
      });
    }
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <ColumnLayout container spacing={3} className="reports dark-panel-content">
          <Panel variant="secondary" className="tools-panel dates-panel">
            <Metric emphasis="low" showIndicator className="metrics-wrapper">
              <MetricHeader title="Asset Class" className="metrics-title"/>
              <MetricContent value={selectedIndices?.assetClass} className="metrics-subtitle" />
            </Metric>
            <Metric emphasis="low" showIndicator>
              <MetricHeader title="CCY" className="metrics-title"/>
              <MetricContent value={selectedIndices?.currency} className="metrics-subtitle"/>
            </Metric>
            <FormField label="Analysis Period" labelPlacement="top" className="reports-date" variant="transparent">
              <DateRangePicker
                EndInputProps={{
                  placeholder: 'End Date',
                }}
                StartInputProps={{
                  placeholder: 'Start Date',
                }}
                endDate={endDate}
                endInputValue={endInputValue}
                onDatesChange={handleDatesChange}
                onInputValuesChange={handleInputValuesChange}
                startDate={startDate}
                startInputValue={startInputValue}
              />
            </FormField>
          </Panel>
        </ColumnLayout>
      </ThemeProvider>
      <ColumnLayout container spacing={3} className="reports-light-panel-content ">
          <ColumnLayout item className="report-filter-elements">
            <GridSearch type="ticker" filter={searchInGrid} searchLabel={"Filter"} searchId="reports-search" helperText="Type to filter report in below grid"/>
          </ColumnLayout>
          <ColumnLayout item className="reports-grid">
            <DataGrid
              columnDefs={ReportsGridColumnDefs}
              rowData={reportsData}
              rowHeight={36}
              domLayout={'autoHeight'}
              onGridReady={onGridReady}
              onCellKeyDown={cellClickHandler}
            />
          </ColumnLayout>
          <ColumnLayout item xs={1} className="footnotes-heading">Footnotes</ColumnLayout>
          {
            cookies.selectedProfile === "US Individual" ?
              <ColumnLayout item xs={11} className="common-footnotes-container" key={footnoteUpdatedData?.[1]?.description?.length  +  footnoteUpdatedData?.[0]?.description?.length + selectedInternalTab}>
                <Footnotes footnote_data={footnoteUpdatedData}/>
              </ColumnLayout> :
              <ColumnLayout item xs={11} className="common-footnotes-container">
                <HtmlTextRendererComponent htmlText={COMMON_FOOTNOTES}/>
              </ColumnLayout>
          }
      </ColumnLayout>
    </>
  );
}

export default Reports;
