import React, {useEffect, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import AccordionWrapper from "../../common/AccordionWrapper/AccordionWrapper";
import HtmlTextRendererComponent from "../../common/HtmlTextRendererComponent";
import "./Footnotes.scss";

interface FootnotesProps {
  changeHandler?: () => void
  footnote_data: any[]
}

const Footnotes = ({changeHandler, footnote_data}:FootnotesProps) => {
  const [updatedPanelsData, setUpdatedPanelsData] = useState<any[]>([]);
  const [panelNames, setPanelNames] = useState<string[]>([]);

  useEffect(() => {
    panelsData(footnote_data);
    getPanelNames(footnote_data);
  },[]);

  const panelsData = (footNotesData) => {
    let panelNum = 0;
    const panels = footNotesData?.map(value => {
      const panel_name = `Panel_${panelNum}`;
      const panelObject = {};
      panelObject[panel_name] = {
        summary: (
          <ColumnLayout container spacing={3} className="accordion-panel-heading" >
            <ColumnLayout item xs={9}>
              <h3>{value?.header}</h3>
            </ColumnLayout>
          </ColumnLayout>),
        content: (
          <HtmlTextRendererComponent  htmlText={value?.description}/>
        ),
      }
      panelNum++
      return panelObject;
    });
    setUpdatedPanelsData(panels);
  }

  const getPanelNames = (footNotesData) => {
    let panelNum = 0;
    const pNames = footNotesData?.map(value => {
      return`Panel_${panelNum++}`;
    });
    setPanelNames(pNames);
  }

  return (
    <ColumnLayout container className="footnotes">
      <ColumnLayout item xs className="accordion-container">
        <AccordionWrapper panelsData={updatedPanelsData} expandedPanels={[]} panelsArray={panelNames} onChange={changeHandler}/>
      </ColumnLayout>
    </ColumnLayout>
  );
};

export default Footnotes;
