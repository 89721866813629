import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import '@jpmuitk/style/css/jpmuitk.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LicenseManager } from 'ag-grid-enterprise';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
LicenseManager.setLicenseKey(
  'CompanyName=SHI Corporation UK Limited_on_behalf_of_JPMorgan Chase (Glasgow),LicensedGroup=Mansart / Indices,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=2,AssetReference=AG-029082,SupportServicesEnd=8_June_2023_[v2]_MTY4NjE3ODgwMDAwMA==130e8c02dc3120d08d4c4380f4e67ffd'
);
const theme = createToolkitTheme('light');
const queryClient = new QueryClient({});
function Root() {
  return (
    <BrowserRouter>
      <AriaAnnouncerProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ThemeProvider>
      </AriaAnnouncerProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
