import {analyticsParam, documentsParam, reportsParam, timeseriesParam} from "../../../utils/Utils";
/*Notices Data Query URLs*/
const NOTICES_URL_US_INDIVIDUAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/notices?region=US&clientType=INDIVIDUAL`;
const NOTICES_URL_US_INSTITUTIONAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/notices?region=US&clientType=PROFESSIONAL`;
const NOTICES_URL_NON_US_PRIVATE = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/notices?region=NON_US&clientType=PRIVATE`;
const NOTICES_URL_NON_US_PROFESSIONAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/notices?region=NON_US&clientType=PROFESSIONAL`;
/*Indices Data Query URLs*/
const INDICES_URL_US_INDIVIDUAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/strategies?region=US&clientType=INDIVIDUAL`;
const INDICES_URL_NON_US_PRIVATE = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/strategies?region=NON_US&clientType=PRIVATE`;
const INDICES_URL_NON_US_PROFESSIONAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/strategies?region=NON_US&clientType=PROFESSIONAL`;
/*Timeseries Data Query URLs*/
const TIMESERIES_URL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/timeseries/`;


/*Metrics Data Query URLs*/
const ANALYTICS_URL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/analytics`;

/*Reports Data Query URLs*/
const REPORTS_URL_US_INDIVIDUAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/reports/`;
const REPORTS_URL_NON_US_PRIVATE = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/reports/`;
const REPORTS_URL_NON_US_PROFESSIONAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/reports/`;

/*MonthlyReturn Data Query URLs*/
const DOCUMENTS_URL_US_INDIVIDUAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/documents/`;
const DOCUMENTS_URL_NON_US_PRIVATE = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/documents/`;
const DOCUMENTS_URL_NON_US_PROFESSIONAL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}/indices/documents/`;



/*Notices Data Fetch Queries*/
export async function fetchNoticesUsIndividual(){
  return(await fetch(NOTICES_URL_US_INDIVIDUAL)).json();
}
export async function fetchNoticesUsInstitutional(){
  return(await fetch(NOTICES_URL_US_INSTITUTIONAL)).json();
}

export async function fetchNoticesNonUsPrivate(){
  return(await fetch(NOTICES_URL_NON_US_PRIVATE)).json();
}

export async function fetchNoticesNonUsProfessional(){
  return(await fetch(NOTICES_URL_NON_US_PROFESSIONAL)).json();
}

/*Indices Data Fetch Queries*/
export async function fetchIndicesUsIndividual(){
  return(await fetch(INDICES_URL_US_INDIVIDUAL)).json();
}

export async function fetchIndicesNonUsPrivate(){
  return(await fetch(INDICES_URL_NON_US_PRIVATE)).json();
}

export async function fetchIndicesNonUsProfessional(){
  return(await fetch(INDICES_URL_NON_US_PROFESSIONAL)).json();
}

/*Timeseries Data Fetch Queries*/

export async function fetchTimeseries(params){
  return(await fetch(`${TIMESERIES_URL}${timeseriesParam}`)).json();
}

/*Metrics Data Fetch Queries*/
export async function fetchAnalyticsData(params){
  return(await fetch(`${ANALYTICS_URL}${analyticsParam}`)).json();
}


/*Reports Data Fetch Queries*/
export async function fetchReportsUsIndividual(){
  return(await fetch(`${REPORTS_URL_US_INDIVIDUAL}${reportsParam}&region=US&clientType=INDIVIDUAL`)).json();
}

export async function fetchReportsNonUsPrivate(){
  return(await fetch(`${REPORTS_URL_NON_US_PRIVATE}${reportsParam}&region=NON_US&clientType=PRIVATE`)).json();
}

export async function fetchReportsUsProfessional(){
  return(await fetch(`${REPORTS_URL_NON_US_PROFESSIONAL}${reportsParam}&region=NON_US&clientType=PROFESSIONAL`)).json();
}

/*MonthlyReturn Data Fetch Queries*/

export async function fetchDocumentsUsIndividual(){
  return(await fetch(`${DOCUMENTS_URL_US_INDIVIDUAL}${documentsParam}?region=US&clientType=INDIVIDUAL`)).json();
}

export async function fetchDocumentsNonUsPrivate(){
  return(await fetch(`${DOCUMENTS_URL_NON_US_PRIVATE}${documentsParam}?region=NON_US&clientType=PRIVATE`)).json();
}

export async function fetchDocumentsUsProfessional(){
  return(await fetch(`${DOCUMENTS_URL_NON_US_PROFESSIONAL}${documentsParam}?region=NON_US&clientType=PROFESSIONAL`)).json();
}
