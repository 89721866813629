import React, {useState, useCallback, useMemo, Suspense, useEffect} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import { Tabs } from '@jpmuitk/tabs';
import './SelectedIndices.scss';
import Summary from "./Summary/Summary";
import Reports from "./Reports/Reports";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectedIndicesSelector, setSelectedInternalTab} from "../../../store/slice/indices/indices";

const theme = createToolkitTheme('dark');

const SelectedIndices = () => {
  const tabs = ['Summary', 'Reports'];
  const tabs_id = ['Summary', 'Reports'];
  const selectedIndices = useAppSelector(selectedIndicesSelector);
  const dispatch = useAppDispatch();
  const [currentIndicesSection, SetCurrentIndicesSection] = useState(0);
    const tabClick = index => {
      SetCurrentIndicesSection(index);
      dispatch(setSelectedInternalTab(tabs[index]));
    };
    const tabChangeHandler = useCallback((event, index) => {
      tabClick(index);
      SetCurrentIndicesSection(index);
    }, []);
    const memoizedTabs = useMemo(() => {
      if(!selectedIndices?.[0]?.hasReports) {
        tabs.pop();
        tabs_id.pop();
      }
      return (
        <Tabs tabs={tabs} onTabClick={tabChangeHandler} value={currentIndicesSection} tabIds={tabs_id}/>
      )
    },[currentIndicesSection, selectedIndices]);

    const memoizedTabContent = useMemo(() => {
      const renderContent = name => {

        switch (name) {
          case 'Summary':
            return <Summary/>;
          case 'Reports':
            return <Reports/>;
        }
      };

      return <Suspense fallback={"...loading"}> {renderContent(tabs[currentIndicesSection])}</Suspense>;
    }, [currentIndicesSection]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <ColumnLayout container spacing={3} className="selected-indices dark-panel-content">
          <ColumnLayout item xs={12} className="indices-tabs-container">{memoizedTabs}</ColumnLayout>
        </ColumnLayout>
      </ThemeProvider>
      <ColumnLayout container spacing={3} className="tab-content">
        <ColumnLayout item xs={12} className="tabs-container">{memoizedTabContent}</ColumnLayout>
      </ColumnLayout>
    </>
  )
}
export default SelectedIndices;
