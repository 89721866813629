import React from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import Footer from '../common/Footer/Footer';
import './LandingPage.scss';
import ProfileSelector from "./ProfileSelector/ProfileSelector";

const LandingPage = () => {
return (
  <ColumnLayout container spacing={3} className='landing-page'>
    <ColumnLayout container className='landing-container'>
      <ColumnLayout item xs={6} className='left-container'>
        <div className='landing-heading landing-heading-1' >J.P.Morgan</div>
        <div className='landing-heading landing-heading-2' >Investable Indices</div>
      </ColumnLayout>
      <ColumnLayout item xs={6} className='right-container' alignItems={'flex-end'}>
        <ProfileSelector/>
      </ColumnLayout>
    </ColumnLayout>
  </ColumnLayout>
)
}

export default LandingPage;
