import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  blockInteraction: boolean;
}

const initialState: AppState = {
  blockInteraction: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setBlockInteraction: (state, action: PayloadAction<boolean>) => {
      state.blockInteraction = action.payload;
    }
  }
});

// actions
export const { setBlockInteraction } = appSlice.actions;

// selectors
const selectSelf = state => state.app;
export const blockInteractionSelector = createDraftSafeSelector(selectSelf, state => state.blockInteraction);

// reducer
export default appSlice.reducer;
