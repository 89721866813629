import DatePeriod from '../models/DatePeriod';
import DateRange from '../models/DateRange';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

export enum Periodicity {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
  NO_PERIODICITY = 'NO_PERIODICITY'
}

export const periodicityMap = {
  [DatePeriod.M1]: Periodicity.WEEKLY,
  [DatePeriod.M3]: Periodicity.WEEKLY,
  [DatePeriod.YTD]: Periodicity.MONTHLY,
  [DatePeriod.Y1]: Periodicity.MONTHLY,
  [DatePeriod.Y3]: Periodicity.QUARTERLY,
  [DatePeriod.Y5]: Periodicity.YEARLY,
  [DatePeriod.MAX]: Periodicity.YEARLY
};

export interface DateRangeState {
  touched: boolean;
  updated: boolean;
  D1: Moment;
  [DatePeriod.MTD]: Moment;
  [DatePeriod.M1]: Moment;
  [DatePeriod.M3]: Moment;
  [DatePeriod.YTD]: Moment;
  [DatePeriod.Y1]: Moment;
  [DatePeriod.Y2]: Moment;
  [DatePeriod.Y3]: Moment;
  [DatePeriod.Y5]: Moment;
  [DatePeriod.MAX]: Moment;
  selectedPeriod: DatePeriod;
  startDate: Moment;
  endDate: Moment;
  periodicity: Periodicity;
}

const excludeWeekends = (date: moment.Moment | undefined): Moment => {
  let thisDay;
  thisDay = date?.clone();
  while (thisDay?.day() === 6 || thisDay?.day() === 0) {
    thisDay = thisDay.clone().subtract(1, 'day');
  }
  return thisDay;
};

const initialDateRangeState = (
  availableDates: moment.Moment[] | undefined,
  baseDate?: Moment,
  start?: object | JSON,
  end?: string,
  selectedPeriod?: DatePeriod
): DateRangeState => {
  // determines the availableEndDate used as fallback if not availableDates/start object defined
  let availableEndDate: moment.Moment | undefined;
  const today = moment().startOf('day');
  if (availableDates && availableDates.length > 0) {
    availableEndDate = availableDates.filter(date => date.isBefore(today)).pop();
  } else {
    availableEndDate = moment().subtract(1, 'days');
  }

  const getAvailableStartDate = (datePeriod: DatePeriod): Moment => {
    let startDate: Moment;
    switch (datePeriod) {
      case DatePeriod.MTD:
        startDate = (start as any)?.MTD?.date
          ? moment((start as any).MTD.date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone()?.subtract(1, 'month').endOf('month'));
        break;
      case DatePeriod.M1:
        startDate = start?.['-1M']?.date
          ? moment(start['-1M'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(1, 'month'));
        break;
      case DatePeriod.M3:
        startDate = start?.['-3M']?.date
          ? moment(start['-3M'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(3, 'month'));
        break;
      case DatePeriod.YTD:
        startDate = (start as any)?.YTD?.date
          ? moment((start as any)?.YTD.date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(1, 'years').set('month', 11).set('date', 31));
        break;
      case DatePeriod.Y1:
        startDate = start?.['-1Y']?.date
          ? moment(start['-1Y'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(1, 'years'));
        break;
      case DatePeriod.Y2:
        startDate = start?.['-2Y']?.date
          ? moment(start['-2Y'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(2, 'years'));
        break;
      case DatePeriod.Y3:
        startDate = start?.['-3Y']?.date
          ? moment(start['-3Y'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(3, 'years'));
        break;
      case DatePeriod.Y5:
        startDate = start?.['-5Y']?.date
          ? moment(start['-5Y'].date, 'YYYY-MM-DD')
          : excludeWeekends(availableEndDate?.clone().subtract(5, 'years'));
        break;
      case DatePeriod.MAX:
        startDate = (start as any)?.All?.date
          ? moment((start as any).All.date, 'YYYY-MM-DD')
          : excludeWeekends(availableDates?.[0]);
        break;
    }

    // @ts-ignore
    return startDate;
  };

  return {
    touched: false,
    updated: false,
    D1: availableEndDate,
    [DatePeriod.MTD]: getAvailableStartDate(DatePeriod.MTD),
    [DatePeriod.M1]: getAvailableStartDate(DatePeriod.M1),
    [DatePeriod.M3]: getAvailableStartDate(DatePeriod.M3),
    [DatePeriod.YTD]: getAvailableStartDate(DatePeriod.YTD),
    [DatePeriod.Y1]: getAvailableStartDate(DatePeriod.Y1),
    [DatePeriod.Y2]: getAvailableStartDate(DatePeriod.Y2),
    [DatePeriod.Y3]: getAvailableStartDate(DatePeriod.Y3),
    [DatePeriod.Y5]: getAvailableStartDate(DatePeriod.Y5),
    [DatePeriod.MAX]: baseDate
      ? baseDate?.isBefore(getAvailableStartDate(DatePeriod.MAX))
        ? getAvailableStartDate(DatePeriod.MAX)
        : baseDate
      : getAvailableStartDate(DatePeriod.MAX),
    selectedPeriod: selectedPeriod || DatePeriod.Y1,
    startDate: getAvailableStartDate(selectedPeriod || DatePeriod.Y1),
    endDate: availableEndDate,
    periodicity: periodicityMap[DatePeriod.Y1]
  } as DateRangeState;
};

const useDateRangeState = (
  availableDates?: Moment[],
  baseDate?: Moment,
  start?: object | JSON,
  end?: string,
  selectedPeriod?: DatePeriod
) => {
  const [state, setState] = useState(() => initialDateRangeState(availableDates, baseDate, start, end, selectedPeriod));

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      ...initialDateRangeState(availableDates, baseDate, start, end, prevState.selectedPeriod)
    }));
  }, [availableDates]);

  const onPeriodChanged = (selectedPeriod: DatePeriod) => {
    setState(prevState => ({
      ...prevState,
      touched: true,
      startDate: prevState[selectedPeriod],
      endDate: prevState.D1,
      selectedPeriod,
      periodicity: periodicityMap[selectedPeriod]
    }));
  };

  const onStartEndChanged = (newStart?: JSON, newEnd?: string) => {
    setState(prevState => ({
      ...prevState,
      ...initialDateRangeState(availableDates, baseDate, newStart, newEnd)
    }));
  };

  const onDatesChanged = ({ startDate, endDate }: DateRange) => {
    if (!startDate || !endDate) {
      return;
    }
    if (!startDate.isValid() || !endDate.isValid()) {
      return;
    }
    /*Below is commented for later enhancement*/
    // const periodicity = getPeriodicityFromDateRange({ startDate, endDate });
    // @ts-ignore
    setState(prevState => {
      if (startDate.toString() === prevState.startDate.toString() && endDate.toString() === prevState.endDate.toString()) {
        return prevState;
      } else {
        return { ...prevState, touched: true, startDate, endDate, selectedPeriod: null };
      }
    });
  };


  return {
    state,
    onPeriodChanged,
    onStartEndChanged,
    onDatesChanged
  };
};

export default useDateRangeState;
