import React from 'react';
import { AppHeaderNext } from '@jpmuitk/app-header';
import { ColumnLayout } from '@jpmuitk/column-layout';
import "./Footer.scss";
import CookiesPolicy from "../../CookiesPolicy/CookiesPolicy";
import TermsOfUse from "../../TermsOfUse/TermsOfUse";
import PrivacyLink from "../../PrivacyLink/PrivacyLink";
import { COPYRIGHT_TEXT } from "../../../utils/TextContent";
const Footer = () => {
  const actionSource = [
    { itemRenderer: () => PrivacyLink() },
    { itemRenderer: () => TermsOfUse() },
    { itemRenderer: () => CookiesPolicy() }
  ];
  return (
    <ColumnLayout container className="footer" justify={'flex-start'}>
      <ColumnLayout item xs={6}>
        <AppHeaderNext
          data-testid="landing-header"
          density="touch"
          className="app-footer"
          logoProps={{height: 15, href:'https://www.jpmorgan.com/global'}}
          actionSource={actionSource}
        />
      </ColumnLayout>
      <ColumnLayout item xs={6} className="copy-right-container">
        <ColumnLayout container className="footer" justify={'flex-end'}>
          <ColumnLayout item>
            <div>
              {COPYRIGHT_TEXT}
            </div>
          </ColumnLayout>
        </ColumnLayout>
      </ColumnLayout>
    </ColumnLayout>

  );
};

export default Footer;
