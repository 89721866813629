import React from 'react';
import { Accordion, ExpansionPanel } from '@jpmuitk/accordion';

interface AccordionWrapperParam {
  panelsData: any,
  expandedPanels: string[],
  panelsArray: string[],
  onChange?: () => void
}
const AccordionWrapper = ({ panelsData, expandedPanels, panelsArray, onChange }: AccordionWrapperParam) => {
  const getPanel = (panels, value) => {
    for (let i = 0; i < panels.length; i++ ) {
      if (panels[i][value]) {
        return panels[i];
      }
    }
  }
  return (
      <Accordion
        defaultExpanded={expandedPanels}
        panels={panelsArray}
        bordered
        density={'medium'}
        className='custom-panel'
        onChange={onChange && onChange}
        renderPanel={({ value, getPanelProps }) => {
          const panel = getPanel(panelsData, value)[value];
          return (
            <ExpansionPanel
              contentClassName="panel-content"
              summaryClassName='accordion-expansion-panel'
              content={panel.content}
              summary={panel.summary}
              {...getPanelProps()}
            />
          );
        }}
      />
  );
};

export default AccordionWrapper;
