import React, {useEffect, useState} from "react";
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Panel } from '@jpmuitk/panel';
import { Chart } from '@jpmuitk/chart';
import {chartOptions, createCustomTooltip, setXAxisDateFormat, setXAxisPlotBand} from './Chart/ChartOptions';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import { Metric, MetricHeader, MetricContent } from '@jpmuitk/metric';
const theme = createToolkitTheme('dark');
import { Button } from '@jpmuitk/button';
import { Icon } from '@jpmuitk/icon';
import './Summary.scss';
import moment, {Moment} from 'moment';
import useDateRangeState from "../../../common/DatePeriodPicker/useDateRangeState";
import DateRange from "../../../common/models/DateRange";
import DatePeriodPicker from "../../../common/DatePeriodPicker/DatePeriodPicker";
import DatePeriod from "../../../common/models/DatePeriod";
import {useCookies} from "react-cookie";
import {
  getAnalyticsQuery,
  getSortedDate,
  getTimeseriesQuery,
  getTimestampFromFormattedDate, getZoomLevel, makeAvailableDates, openInNewTab, toSentenceCase
} from "../../../../utils/Utils";
import { useQuery } from 'react-query';
import Metrics, {IMetricsData} from "src/components/common/Metrics/Metrics";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {selectedIndicesSelector, querySettingsSelector} from "../../../../store/slice/indices/indices";
import Documents from "./Documents/Documents";
import MonthlyReturn from "./MonthlyReturn/MonthlyReturn";
import HtmlTextRendererComponent from "../../../common/HtmlTextRendererComponent";
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import {
  COMMON_FOOTNOTES,
  US_INDIVIDUAL_SUMMERY_FOOTNOTES
} from "../../../../utils/TextContent";
import Footnotes from "../../Footnotes/Footnotes";
import ReturnStatistics from "../RetrunStatistics/ReturnStatistics";
import * as XLSX from "xlsx";
import Loader from "../../../common/Loader/Loader";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsMarkerClusters from 'highcharts/modules/marker-clusters';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HC_more from 'highcharts/highcharts-more';
import HighchartsParallelCoordinates from 'highcharts/modules/parallel-coordinates.js';
[Highcharts].forEach(lib => {
  HighchartsParallelCoordinates(lib);
  HighchartsNoData(lib);
  HighchartsExporting(lib);
  HighchartsExportData(lib);
  HighchartsOfflineExporting(lib);
  HighchartsAccessibility(lib);
  HighchartsMarkerClusters(lib);
  HC_more(lib);
});
interface IdocData {
  description: string,
  link: string
}

interface IselectedDates {
  startDate: Moment,
  endDate: Moment
}


const Summary = () => {
  const [cookies]  = useCookies(['selectedProfile']);
  const querySettings = useAppSelector(querySettingsSelector);
  const dispatch = useAppDispatch();
  const [levelMetrics,setLevelMetrics] = useState<any>([]);
  const [riskMeasure,setRiskMeasure] = useState<any>([]);
  const [returnStatistics,setReturnStatistics] = useState<any>([]);
  // @ts-ignore
  const [options, setOptions] = useState<Highcharts.Options>(chartOptions)//;useState<any>(chartOptions);
  const [availableDates, setAvailableDates] = useState(makeAvailableDates());
  const [filteredChartData, setFilteredChartData] = useState<any[]>([]);
  const [chartHistoryData, setHistoryChartData] = useState([]);
  const [monthlyReturnData, setMonthlyReturnData] = useState([]);
  const [minStartDate, setMinStartDate] = useState<Moment>();
  const [footnoteUpdatedData, setFootnoteUpdatedData] = useState<any[]>(US_INDIVIDUAL_SUMMERY_FOOTNOTES);
  const [additionalDocuments, setAdditionalDocuments] = useState<IdocData[]>([]);
  const [notices, setNotices] = useState<IdocData[]>([]);
  const [baseDate, setBaseDate] = useState<Moment>();
  const [chartDataError, setChartDataError] = useState(false);
  const [analyticsDataError, setAnalyticsDataError] = useState(false);
  const [currentZoomLeven,  setCurrentZoomLevel] = useState(100);
  const selectedIndicesData = useAppSelector(selectedIndicesSelector);
  const selectedIndices = selectedIndicesData[0];
  const [tickerName, setTickerName] = useState('');
  const [selectedDates, setSelectedDates] = useState<IselectedDates>();
  const analyticsIndex = selectedIndices?.analyticsIndex ? selectedIndices?.analyticsIndex : '';
  const returnType = selectedIndices?.returnType ? selectedIndices?.returnType : '';
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {data, isFetching:isFetchingTimeseries, refetch:reFetchTimeseries} = useQuery(
    ["Timeseries"],
    getTimeseriesQuery(tickerName),
    {enabled: false},
    querySettings
  );
  const {data:metricsData, isFetching:fetchingMetrics, refetch:reFetchAnalytics} = useQuery(
    ["Metrics"],
    getAnalyticsQuery(`/${selectedIndices?.ticker?.split(" ")[0]}?startDate=${selectedDates?.startDate?.format('YYYY-MM-DD')}&endDate=${selectedDates?.endDate?.format('YYYY-MM-DD')}&analyticsIndex=${analyticsIndex}&strategyReturnType=${returnType}`),
    {enabled: false},
    querySettings
  );

  const chartRef = React.createRef<any>();
  useEffect(() => {
    setTickerName(selectedIndices?.ticker?.split(" ")[0]);
  }, [selectedIndices]);

  useEffect(() => {
    if(tickerName?.length > 0) {
      reFetchTimeseries();
    }
  }, [tickerName]);

  useEffect(() => {
    window.onresize = () => {
      setCurrentZoomLevel(getZoomLevel());
    }
  })

  const setFootnotes = () => {
    const US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY = JSON.parse(JSON.stringify(US_INDIVIDUAL_SUMMERY_FOOTNOTES)) as typeof US_INDIVIDUAL_SUMMERY_FOOTNOTES;
    if (selectedIndices?.simulatedReturns) {
      US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY[0].description = `<h3>Use of Simulated Returns</h3><div>${selectedIndices?.simulatedReturns}</div> ${US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY[0].description}`;
    }
    const currentData = [...US_INDIVIDUAL_SUMMERY_FOOTNOTES_COPY];
    if (selectedIndices?.footnotes) {
      currentData[1].description=selectedIndices?.footnotes;
    } else {
      currentData.splice(1,1);
    }
    if(selectedIndices?.additionalDocuments?.documents) {
      setAdditionalDocuments(selectedIndices?.additionalDocuments?.documents);
    }
    if(selectedIndices?.additionalDocuments?.notices) {
      setNotices(selectedIndices?.additionalDocuments?.notices);
    }
    setFootnoteUpdatedData(currentData);
  }
  useEffect(() => {
    setFootnotes();
  }, [selectedIndices]);
  const getAllHistory = (data) => {
    const seriesObj = data?.data;
    const filteredData = [];
    const allDates = seriesObj;
    allDates?.forEach(item => {
      const thisDate = getTimestampFromFormattedDate(moment((item.x))?.format('YYYY-MM-DD'));
      if (thisDate) {
        // @ts-ignore
        filteredData.push({
          x: moment(item.x).valueOf(),
          y: item.y
        });
      }
    });
    const sortedDates = filteredData;
    return sortedDates;
  }
  useEffect(() => {
    if(data && !data?.errors) {
      const sortedData = data?.data;
      const maxDate = sortedData?.[sortedData.length-1].x;
      const minDate =  moment(maxDate).subtract(1, 'years').unix();
      const selectedChartData:any = [];
      data.data.map(value => {
        if(moment(moment(value.x).format('YYYY-DD-MM')).isSameOrAfter(moment(moment(minDate).format('YYYY-DD-MM'))) && moment(moment(value.x).format('YYYY-DD-MM')).isSameOrBefore(moment(moment(maxDate).format('YYYY-DD-MM')))) {
          selectedChartData.push(value);
        }
      })
      setChartDataError(false);
      selectedChartData.sort((a, b) => {
        return parseFloat(a.x) - parseFloat(b.x);
      })
      // @ts-ignore

      options.series = [{data: selectedChartData, name:data.id}];
      setOptions(options);
      const availableDates = data?.data?.map(val => {
        return moment(val?.x);
      });
      setHistoryChartData(getAllHistory(data));
      setAvailableDates(availableDates);
      if(data?.data) {
        setMinStartDate(data?.data?.[0].x);
      }
    } else {
      setChartDataError(true);
    }
  }, [data]);

  useEffect(() => {
    if(metricsData && !metricsData?.errors) {
      setAnalyticsDataError(false);
      const levelArr:IMetricsData[] = [];
      metricsData?.index?.map(value => {
        levelArr.push({
          header: value.type,
          column_1: value.date === null ? '-' : value.date,
          column_2: value.value,
        })
      });
      setLevelMetrics(levelArr);
      if (levelArr[levelArr.length-1].header === "Period Index Return" && cookies.selectedProfile !== "US Individual") {
        levelArr.pop();
      }
      const riskArr:IMetricsData[] = [];
      for (let key in metricsData?.riskMeasure) {
        riskArr.push({
          header: key,
          column_1: undefined,
          column_2: metricsData?.riskMeasure?.[key]
        })
      }
      setRiskMeasure(riskArr);

      const returnStatisticsArr:IMetricsData[] = [];
      for (let key in metricsData?.returnStatistics) {
        returnStatisticsArr.push({
          header: key,
          column_1: undefined,
          column_2: metricsData?.returnStatistics?.[key]
        });

      }
      setReturnStatistics(returnStatisticsArr);
      setMonthlyReturnData(metricsData?.monthlyReturnStatistics);
    } else {
      setAnalyticsDataError(true);
    }
  },[metricsData]);

  useEffect(() => {
    filteredChartData.sort((a, b) => {
      return parseFloat(a.x) - parseFloat(b.x);
    })
      // @ts-ignore
    options.series = [{data: filteredChartData, name:data?.id}];
    setXAxisPlotBand(moment(selectedIndices?.liveDate).format('DD-MMM-YYYY'), filteredChartData[filteredChartData.length-1]?.x, filteredChartData[0]?.x);
      setOptions(options);
      const availableDates = filteredChartData.map(val => {
        // @ts-ignore
        return moment(val?.x);
      });
  },[filteredChartData]);



  useEffect(() => {
    if (chartRef?.current) {
      createCustomTooltip(cookies.selectedProfile === "US Individual" ? 'MMM DD, YY' : 'DD MMM YY');
      setXAxisDateFormat(cookies.selectedProfile === "US Individual" ? 'MMM DD, YY' : 'DD MMM YY');
    }
  },[chartRef]);

  const getDocumentsButtons = docData => {
   return  docData.map(value => {
     return <OrderedButton className="document-buttons" variant="secondary" onClick={() => openInNewTab(value.link)}>
       <Icon name="tear-out" style={{ marginRight: 5 }} className="document-icon" />
       {value.description}
     </OrderedButton>
    })
  }
  const getNotices = noticeData => {
    return  noticeData.map(value => {
      return <OrderedButton className="document-buttons" variant="secondary" onClick={() => openInNewTab(value.link)}>
        <Icon name="tear-out" style={{ marginRight: 5 }} className="document-icon" />
        {value.description}
      </OrderedButton>
    })
  }

  const {
    state: dateRangeState,
    onPeriodChanged,
    onDatesChanged
  } = useDateRangeState(availableDates, availableDates[0]?.clone());

  const onChangeWrapper = (dateRange: DateRange) => {
    if(!dateRange?.startDate || !dateRange?.endDate) {
      return false;
    }
   const chartData =  getSeriesDataForDateRange(data, dateRange?.startDate, dateRange?.endDate);
    const { startDate, endDate } = dateRange;
    setFilteredChartData(chartData);
    onDatesChanged({ startDate, endDate });
    setSelectedDates({startDate: dateRange?.startDate, endDate:dateRange?.endDate});
  }
  const periodChangeHandler = period => {
    onPeriodChanged(period);
  };
  useEffect(() => {
    const chartData =  getSeriesDataForDateRange(data, dateRangeState?.startDate, dateRangeState?.endDate);
    setFilteredChartData(chartData);
    setSelectedDates({startDate: dateRangeState.startDate, endDate:dateRangeState.endDate});
  },[dateRangeState]);

  useEffect(()=> {
    if(selectedDates?.startDate){
      reFetchAnalytics();
    }
  }, [selectedDates])
  useEffect(() => {
    if(availableDates.length > 0) {
      setBaseDate(availableDates?.[0]);
    }
  }, [availableDates])
  const getSeriesDataForDateRange = (data, start_Date, end_Date) => {
    const seriesObj = data?.data;
    const filteredData = [];
    const stDate =start_Date ? start_Date : moment(minStartDate);
    const startDate = getTimestampFromFormattedDate(stDate?.format('YYYY-MM-DD'));
    const endDate = getTimestampFromFormattedDate(end_Date?.format('YYYY-MM-DD'));

    const allDates = seriesObj;
    allDates?.forEach(item => {
      const thisDate = getTimestampFromFormattedDate(moment((item.x))?.format('YYYY-MM-DD'));
      const useThisDate = thisDate >= startDate && thisDate <= endDate;
      if (useThisDate) {
        // @ts-ignore
        filteredData.push({
          x: moment(item.x).valueOf(),
          y: item.y
        });
      }
    });

    const sortedDates = getSortedDate(filteredData);

    return sortedDates;
  };

  const exportCurrentStatistics = () => {
      const statisticsDataWorkBook = XLSX.utils.book_new();
      const indexDetailsArr:any[] = [];
      const keyArr = ['indexName', 'indexDescription', 'assetClass', 'currency', 'ticker']
      for (let key in selectedIndices) {
        if(keyArr.indexOf(key) !== -1) {
          indexDetailsArr.push({Type:toSentenceCase(key), Value:selectedIndices[key] === null ? '-' : selectedIndices[key]});
        }
      }
      const indexDetailsSheet = XLSX.utils.json_to_sheet(indexDetailsArr);
      XLSX.utils.book_append_sheet(statisticsDataWorkBook, indexDetailsSheet, "Index Details");
      let levelData: any[] = [];
      metricsData?.index.map(val => {
        levelData.push({Type: toSentenceCase(val.type), Date: val.date, Value: val.value});
      })
    const statisticsLevelSheet = XLSX.utils.json_to_sheet(levelData);
      XLSX.utils.book_append_sheet(statisticsDataWorkBook, statisticsLevelSheet, "Level");
      if(cookies.selectedProfile !== "US Individual") {
        const riskData:any[] = [];
        for (let key in metricsData?.riskMeasure) {
          riskData.push({Type:toSentenceCase(key), Value:metricsData?.riskMeasure[key]});
        }
        const riskMeasureSheet = XLSX.utils.json_to_sheet(riskData);
        const returnData:any[] = [];
        for (let key in metricsData?.returnStatistics) {
          returnData.push({Type:toSentenceCase(key), Value:metricsData?.returnStatistics[key]});
        }
        const returnStatisticsSheet = XLSX.utils.json_to_sheet(returnData);
        XLSX.utils.book_append_sheet(statisticsDataWorkBook, riskMeasureSheet, "Risk Measure");
        XLSX.utils.book_append_sheet(statisticsDataWorkBook, returnStatisticsSheet, "Return Statistics");
      }
      XLSX.writeFile(statisticsDataWorkBook, "analyticsDataSheet.xlsx");
    }

    const downloadIndexHistory = () => {
      const indexHistory:any[] = [];
      chartHistoryData.map(value => {
        // @ts-ignore
        const tempObj = {"Index Ticker":data?.id, Date: moment(value?.x).format("DD-MMM-YY") , Level: value?.y}
        indexHistory.push(tempObj);
      })
      const indexHistorySheet = XLSX.utils.json_to_sheet(indexHistory);
      const indexHistoryWorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(indexHistoryWorkBook, indexHistorySheet, "Index History");
      XLSX.writeFile(indexHistoryWorkBook, "completeIndexHistory.xlsx");
    }

  return (
    <>
      <ThemeProvider theme={theme}>
        <ColumnLayout container spacing={3} className="summery dark-panel-content">
          <ColumnLayout item xs={9} className="metric-and-datepicker-container">
          <Panel variant="secondary" className="tools-panel dates-panel">
            <Metric emphasis="low" showIndicator className="metrics-wrapper">
              <MetricHeader title="Asset Class" className="metrics-title"/>
              <MetricContent value={selectedIndices?.assetClass === null ? '-' : selectedIndices?.assetClass} className="metrics-subtitle" />
            </Metric>
            <Metric emphasis="low" showIndicator>
              <MetricHeader title="CCY" className="metrics-title"/>
              <MetricContent value={selectedIndices?.currency === null ? '-' : selectedIndices?.currency} className="metrics-subtitle"/>
            </Metric>
            {!chartDataError ? <div className="datepicker-container">
              {filteredChartData ? <DatePeriodPicker
                selectedPeriod={dateRangeState?.selectedPeriod}
                onPeriodChanged={periodChangeHandler}
                datesChange={onChangeWrapper}
                startDate={dateRangeState?.startDate ? dateRangeState?.startDate : moment(minStartDate)}
                endDate={dateRangeState?.endDate}
                baseDate={baseDate ? baseDate : availableDates?.[0]}
                dateRangeState={dateRangeState}
                availableDates={availableDates}
                includePeriods={[DatePeriod.MTD, DatePeriod.M1, DatePeriod.M3, DatePeriod.YTD, DatePeriod.Y1, DatePeriod.Y3, DatePeriod.Y5, DatePeriod.MAX]}
                dateFormat={cookies.selectedProfile === "US Individual" ? 'MMM DD, YYYY' : 'DD-MMM-YYYY'}
              /> : null}
            </div> : null }
          </Panel>
          </ColumnLayout>
          <ColumnLayout item xs={3} className="download-container">
            <Panel variant="secondary" className="tools-panel">
              <ColumnLayout container justify="flex-end">
                {cookies.selectedProfile !== "US Individual" ? <Button className="download-button" onClick={exportCurrentStatistics}
                         disabled={!chartDataError ? false : true}>
                  <Icon accessibleText="Search" name="download"/>Current statistics
                </Button> : null}
                <Button variant="cta" onClick={downloadIndexHistory} disabled={!chartDataError ? false : true}>
                  <Icon accessibleText="Search" name="download"/>Complete Index History
                </Button>
              </ColumnLayout>

            </Panel>
          </ColumnLayout>
          { fetchingMetrics ? <ColumnLayout item className="chart-container" xs={currentZoomLeven < 150 ? 3 : 4} > <Loader/> </ColumnLayout>: !analyticsDataError ? <ColumnLayout item className="chart-container" xs={currentZoomLeven < 150 ? 3 : 4} >
          <div className="level-metrics-heading">Level</div>
          <Metrics
            metricsData={levelMetrics}
            columns={2}
            column_1_Class="level-column-1"
            column_2_Class="level-column-2"
            headerClass="level-metric-header"
          />
            {cookies.selectedProfile !== "US Individual" ?<div>
              <div className="risk-metrics-heading">Risk Measure</div>
              <Metrics
                metricsData={riskMeasure}
                columns={1}
                column_1_Class="level-column-1"
                column_2_Class="level-column-2"
                headerClass="level-metric-header-risk"
              />
            </div> :
              <ColumnLayout container>
                {additionalDocuments.length > 0 ? <ColumnLayout item xs={12} className="additional-docs-buttons">
                  <div className="documents-heading">Documents</div>
                  <ButtonBar alignLeft stackAtBreakpoint="xl">
                    {getDocumentsButtons(additionalDocuments)}
                  </ButtonBar>
                </ColumnLayout> : null}
                {notices.length > 0 ? <ColumnLayout item xs={12} className="additional-docs-buttons">
                  <div className="documents-heading">Notices</div>
                  <ButtonBar alignLeft stackAtBreakpoint="xl">
                    {getNotices(notices)}
                  </ButtonBar>
                </ColumnLayout> : null }
              </ColumnLayout>}
        </ColumnLayout> : null}
          {isFetchingTimeseries ? <ColumnLayout item className="chart-container" xs={9} > <Loader/> </ColumnLayout> : !chartDataError ? <ColumnLayout item className="chart-container" xs={9}>
            <Chart highcharts={Highcharts} options={options} chartRef={chartRef}/>
          </ColumnLayout >: null }
        </ColumnLayout>
      </ThemeProvider>
      <ColumnLayout container spacing={3} className="light-panel-content">
        {cookies.selectedProfile !== "US Individual" ? <ColumnLayout item className="documents-container" xs={12}>
          <Documents/>
        </ColumnLayout> : null}
        {cookies.selectedProfile !== "US Individual" && selectedDates ?
         <ReturnStatistics returnStatistics={returnStatistics} selectedDates={selectedDates}/>  : null}
        {cookies.selectedProfile !== "US Individual" ? <ColumnLayout item className="documents-container" xs={12}>
          <MonthlyReturn monthlyReturnData={monthlyReturnData}/>
        </ColumnLayout> : null }
        <ColumnLayout item xs={1} className="footnotes-heading">Footnotes</ColumnLayout>
        {
          cookies.selectedProfile === "US Individual" ?
            <ColumnLayout item xs={11} className="common-footnotes-container" key={footnoteUpdatedData?.[1]?.description?.length +  footnoteUpdatedData?.[0]?.description?.length}>
              <Footnotes footnote_data={footnoteUpdatedData}/>
            </ColumnLayout>:
          <ColumnLayout item xs={11} className="common-footnotes-container">
            <HtmlTextRendererComponent htmlText={COMMON_FOOTNOTES}/>
          </ColumnLayout>
        }
      </ColumnLayout>
    </>
  );
}

export default Summary;
