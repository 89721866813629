import React, {useEffect, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { useQuery } from 'react-query';
import AccordionWrapper from "../common/AccordionWrapper/AccordionWrapper";
import HtmlTextRendererComponent from "../common/HtmlTextRendererComponent";
import { Button } from '@jpmuitk/button';
import { FormField } from '@jpmuitk/form-field';
import { Search } from '@jpmuitk/search';
import moment from 'moment';
import "./Notices.scss";
import Loader from "../common/Loader/Loader";
import {getNoticesQuery} from "../../utils/Utils";
import {useCookies} from "react-cookie";
import {useAppSelector} from "../../store/hooks";
import {querySettingsSelector, selectedProfileSelector} from "../../store/slice/indices/indices";
const Notices = () => {
  const querySettings = useAppSelector(querySettingsSelector);
  const [cookies]  = useCookies(['selectedProfile']);
  const {data, isFetching} = useQuery(["Notices"], getNoticesQuery(cookies.selectedProfile), querySettings);
  const [noticesData, setNoticesData] = useState<any[]>([]);
  const [filteredNoticesData, setFilteredNoticesData] = useState<any[]>([]);
  const [updatedPanelsData, setUpdatedPanelsData] = useState<any[]>([]);
  const [panelNames, setPanelNames] = useState<string[]>([]);
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const region = selectedProfile.split(' ').slice(0, -1).join('_').toUpperCase();
  const clientType = selectedProfile === 'US Institutional' ? 'PROFESSIONAL' : selectedProfile.split(' ').pop().toUpperCase();
  const downloadDocument = (event, value) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/indices/content/all/${value?.documentName}${value?.fileFormat}?region=${region}&clientType=${clientType}&documentType=NOTICE`, '_blank');
  }

  useEffect(() => {
    if(data) {
      setNoticesData(data);
    }
  }, [data]);

  useEffect(() => {
    if(noticesData.length > 0) {
      panelsData(noticesData);
      getPanelNames(noticesData);
    }
  },[noticesData]);

  useEffect(() => {
    if(filteredNoticesData?.length > 0) {
      panelsData(filteredNoticesData);
      getPanelNames(filteredNoticesData);
    }
  },[filteredNoticesData]);

  const panelsData = (noticesData) => {
    if(noticesData.length > 0) {
      let panelNum = 0;
      const panels = noticesData?.map(value => {
        const panel_name = `Panel_${panelNum}`;
        const panelObject = {};
        panelObject[panel_name] = {
          summary: (
            <ColumnLayout container spacing={3} className="accordion-panel-heading">
              <ColumnLayout item xs={9}>
                <h3>{value?.noticeName}{value?.isNew ? <span className="is-new">NEW</span> : null}</h3>
              </ColumnLayout>
              <ColumnLayout container xs justify='flex-end'>
                {value?.documentName?.length > 0 ? <Button
                  className="download-button"
                  variant="secondary"
                  onClick={(event) => {
                    downloadDocument(event, value)
                  }}
                >Download</Button> : null}
                <span className="notice-date">{moment(value?.effectiveDate).format("DD MMM YYYY")}</span>
              </ColumnLayout>
            </ColumnLayout>),
          content: (
            <HtmlTextRendererComponent htmlText={value?.description}/>
          ),
        }
        panelNum++
        return panelObject;
      });
      setUpdatedPanelsData(panels);
    }
  }

  const getPanelNames = (noticesData) => {
    let panelNum = 0;
    const pNames = noticesData?.map(value => {
      return`Panel_${panelNum++}`;
    });
    setPanelNames(pNames);
  }

  const filterAccordion = event => {
    const searchedValue = event.target.value;
    if(searchedValue.length < 3) {
      setFilteredNoticesData(noticesData);
    } else {
      const tempNoticeData = [...noticesData];
      const updatedNotices:any[] = [];
      tempNoticeData.map(val => {
        if(val?.noticeName?.toLowerCase().indexOf(searchedValue?.toLowerCase()) !== -1){
          updatedNotices.push(val);
        }
      });
      setFilteredNoticesData(updatedNotices);
    }

  }

  const onClearFilter = () => {
    setFilteredNoticesData(noticesData);
  }

  return (
    <>
      {isFetching && noticesData.length > 0 ? <Loader/> : <ColumnLayout container className="notices">
        <ColumnLayout item xs={2} className="accordion-search">
          {noticesData.length > 0 ? <FormField label="Search Notice" variant='transparent' id='notices-form'>
            <Search
              onChange={(obj) => {
                filterAccordion(obj)
              }}
              onClear={onClearFilter}
              id="notice-search"
            />
          </FormField> : null}
        </ColumnLayout>
        <ColumnLayout item xs className="accordion-container">
          {updatedPanelsData.length > 0 ?
            <AccordionWrapper panelsData={updatedPanelsData} expandedPanels={[]} panelsArray={panelNames}/> : null}
        </ColumnLayout>
      </ColumnLayout>}
      </>
  );
};

export default Notices;
