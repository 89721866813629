export const ABOUT_US = '<div class="content clearfix">' +
  '            <p class="history-intro">J.P. Morgan Investable Indices provides information on strategies and indices of J.P. Morgan\'s Investment Bank. J.P. Morgan is one of the world\'s leaders in derivatives offering a wide range of products to institutional investors, distributors, corporates and private investors. We are a global firm with extensive knowledge of derivatives across all asset classes and pride ourselves on being able to deliver a vast array of products to meet our clients\' needs. J.P.Morgan is a proven pioneer in the derivatives industry, where we have demonstrated our innovative capabilities in multiple areas, including original product offerings and industry transforming initiatives.</p>\n' +
  '\n' +
  '            <p class="iosco-principle">The Investable Index Business of J.P. Morgan Securities plc and J.P. Morgan Securities LLC (the Investable Index Business) is a global, cross-asset enterprise within the Corporate and Investment Bank line of business of JPMorgan Chase &amp; Co.(which together with its subsidiaries forms the J.P. Morgan Group). The Investable Index Business creates investable indices. The Investable Index Business has published its <span id="aboutus-pdf" class="hidden">Opens PDF document in new window.</span> <a aria-describedby="aboutus-pdf" href="http://www.jpmorgan.com/directdoc/IIB_IOSCO_Compliance_Statement.pdf" target="_blank">IOSCO Compliance Statement</a>, as well as <a href="http://www.jpmorgan.com/directdoc/IIB_Supplemental_Disclosure.pdf" target="_blank" aria-describedby="aboutus-pdf">IOSCO, EU Benchmarks Regulation and UK Benchmarks Regulation Supplemental Disclosure</a> and an <a href="http://www.jpmorgan.com/directdoc/Investable_Indices_COI_Disclosure_Statement.pdf" target="_blank" aria-describedby="aboutus-pdf">Investable Indices Conflicts of Interest Disclosure Statement</a>.</p>\n' +
  '\n' +
  '            <p>J.P. Morgan Securities Plc (JPMS plc) was registered as a benchmark administrator under article 34 of the EU Benchmarks Regulation (Regulation (EU) 2016/1011) ("EU BMR"). Due to Brexit, JPMS plc is no longer a benchmark administrator under EU BMR and instead is a benchmark administrator under article 34 of the United Kingdom Benchmarks Regulation ("UK BMR") and can be found listed on the <a href="https://register.fca.org.uk/BenchmarksRegister/s/" target="_blank">FCA Register</a>. The Investable Index Business of JPMS plc creates indices, some of which are considered "benchmarks" under one or both of EU BMR and UK BMR. JPMS plc has published its <a href="http://www.jpmorgan.com/directdoc/EU_BMR_Compliance.pdf" target="_blank" aria-describedby="aboutus-pdf">EU Benchmarks Regulation and UK Benchmarks Regulation Compliance Statement</a>, as well as <a href="http://www.jpmorgan.com/directdoc/IIB_Supplemental_Disclosure.pdf" target="_blank" aria-describedby="aboutus-pdf">IOSCO, EU Benchmarks Regulation and UK Benchmarks Regulation Supplemental Disclosure</a> and a <a href="http://www.jpmorgan.com/directdoc/EU_BMR_IIFamilies.pdf" target="_blank" aria-describedby="aboutus-pdf">list of Benchmark Statements and Benchmark Families</a>.</p>\n' +
  '        </div> ' +
  '<div class="complaints">\n' +
  '        <h3>Changes to Interbank Offered Rates (IBORS) and other benchmark rates</h3>\n' +
  '        <p>For additional information about indices which utilise an IBOR rate, either directly or indirectly, please <a href="https://www.jpmorgan.com/disclosures/interbank_offered_rates" target="_blank">click here</a>.</p>\n' +
  '\n' +
  '        <p>Notice of Libor Remediation - both USD and non-USD - Replacement of constituents referencing certain interbank offered rates (IBORS) and other benchmarks, please <a href="https://www.jpmorgan.com/directdoc/CIB_IIB_LIBOR_Final_Notice_17122021.pdf" target="_blank">click here</a>.</p>\n' +
  '\n'
export const COMPLAINTS_NON_US = '<div class="complaints">\n' +
  '<p>If you have a complaint about a J.P. Morgan Investable Index you can report it to us in a variety of ways. You can call your usual J.P. Morgan contact, or alternatively you can use email or post.</p>\n' +
  '\n' +
  '<div>\n' +
  '<b>Email:</b> <a href="mailTo:Investable.Indices@jpmorgan.com">Investable.Indices@jpmorgan.com</a>' +
  '' +
  '<div class="complaints-sub-heading" >Post:</div>\n' +
  '<div class="post">J.P. Morgan<br>' +
  'Investable Indices - Complaints<br>' +
  '25 Bank Street<br>' +
  'London, E14 5JP<br>' +
  'United Kingdom<br>' +
  '</div>'+

  '        \n' +
  '                <p>Any complaint regarding the person advising on, or selling, any product referencing a J.P. Morgan Investable Index, can be submitted directly to that person.</p>\n' +
  '                <p>Once we have received your complaint, we will acknowledge it, investigate it and respond to you in a fair and timely manner. Complaints made against a J.P. Morgan UK regulated entity will be handled in accordance with J.P. Morgan\'s <span id="complaints-pdf-nonus" class="hidden">Opens PDF document in new window.</span> <a href="http://www.jpmorgan.com/directdoc/UK_Complaints_Handling_Process.pdf" target="_blank" aria-describedby="complaints-pdf-nonus">Complaints Handling Process - UK Regulated Entities</a>.</p>\n' +
  '\n' +
  '            \n' +
  '            \n' +
  '        \n' +
  '\n' +
  '    </div>'
export const COMPLAINTS_US_INDIVIDUAL =
  '<p>If you have a complaint about a J.P. Morgan Investable Index you can report it to us in a variety of ways. You can call your usual J.P. Morgan contact, or alternatively you can use email.</p>\n' +
  '\n' +
  '<p><b>Email:</b> <a href="mailTo:Investable.Indices@jpmorgan.com">Investable.Indices@jpmorgan.com</a></p>\n' +
  '<p>Once we have received your complaint, we will acknowledge it, investigate it and respond to you. Complaints made against a J.P. Morgan UK regulated entity will be handled in accordance with J.P. Morgan\'s <span id="complaints-pdf-nonus" class="hidden">Opens PDF document in new window.</span> <a href="http://www.jpmorgan.com/directdoc/UK_Complaints_Handling_Process.pdf" target="_blank" aria-describedby="complaints-pdf-nonus">Complaints Handling Process - UK Regulated Entities</a>.</p>'


export const COMPLAINTS_US_INSTITUTIONAL =
  '<p>If you have a complaint about a J.P. Morgan Investable Index you can report it to us in a variety of ways. You can call your usual J.P. Morgan contact, or alternatively you can use email.</p>\n' +
  '\n' +
  '<p><b>Email:</b> <a href="mailTo:Investable.Indices@jpmorgan.com">Investable.Indices@jpmorgan.com</a></p>\n' +
  '<p>Once we have received your complaint, we will acknowledge it, investigate it and respond to you. Complaints made against a J.P. Morgan UK regulated entity will be handled in accordance with J.P. Morgan\'s <span id="complaints-pdf-nonus" class="hidden">Opens PDF document in new window.</span> <a href="http://www.jpmorgan.com/directdoc/UK_Complaints_Handling_Process.pdf" target="_blank" aria-describedby="complaints-pdf-nonus">Complaints Handling Process - UK Regulated Entities</a>.</p>'
export const CONTACT_US_INSTITUTIONAL = '<p class="vida-support">You can request access to Investable Indices on our Vida platform for Institutional clients here: <a href="mailto:vida.inquiry@jpmorgan.com">Vida Support</a></p>\n'

export const INDICES_LISTING_FOOTNOTES = [
  {
    header: "General Disclaimer",
    description: "<div><p><b>For certificates of deposit:</b> The information contained on this Website is for discussion purposes only. Any information relating to performance contained in these materials is illustrative and no assurance is given that any indicative returns, performance or results, whether historical or hypothetical, will be achieved. These terms are subject to change, and JPMorgan undertakes no duty to update this information. This information shall be amended, superseded and replaced in its entirety by a subsequent term sheet, disclosure supplement and/or private placement memorandum, and the documents referred to therein. In the event any inconsistency between the information presented herein and any such term sheet, disclosure supplement and/or private placement memorandum, such term sheet, disclosure supplement and/or private placement memorandum shall govern.</p>" +
      "<p>Investments in products linked to an Index require investors to assess several characteristics and risk factors that may not be present in other types of transactions. In reaching a determination as to the appropriateness of any proposed transaction, clients should undertake a thorough independent review of the legal, regulatory, credit, tax, accounting and economic consequences of such transaction in relation to their particular circumstances. This website contains market data from various sources other than us and our affiliates, and, accordingly, we make no representation or warranty as to the market data's accuracy or completeness. All information is subject to change without notice.</p>" +
      "<p>J.P. Morgan indices included on this Website are live. Please refer to the index rules and related index documentation for each J.P. Morgan index described on this page for further information. No person should make an investment decision on the basis of the information available on this page. Any index level for a date prior to the live date (as specified in the index rules or documentation) of a J.P. Morgan index is a hypothetical back-tested level. Any backtesting information provided herein is illustrative only and derived from proprietary models based on certain data, assumptions and estimates.</p></div>"
  },
  {
    header: "Use of Simulated Returns",
    description: "<p>Historical performance measures provided on this website may represent hypothetical backtested performance and may be calculated using alternative performance for some constituents for certain periods. The use of alternative \"proxy\" performance information in the calculation of hypothetical backtested weights and levels may have resulted in different, perhaps significantly different, weights and higher levels than would have resulted from the use of actual performance information of the constituents. This type of information has inherent limitations and you should carefully consider these limitations before placing reliance on such information.  Alternative modelling techniques might produce significantly different results and may prove to be more appropriate. Any information relating to performance contained on this website is illustrative and no assurance is given that any indicative returns, performance or results, whether historical or hypothetical, will be achieved. PAST PERFORMANCE, AND ESPECIALLY HYPOTHETICAL BACKTESTED PERFORMANCE, IS NOT INDICATIVE OF FUTURE RESULTS.</p>"
  },
  {
    header: "IRS Circular 230 Disclosure",
    description: "<p>We and our affiliates do not provide tax advice. Accordingly, any discussion of U.S. tax matters contained herein is not intended or written to be used, and cannot be used, in connection with the promotion, marketing or recommendation by anyone unaffiliated with J.P. Morgan of any of the matters address herein or for the purpose of avoiding U.S. tax-related penalties. Investment suitability must be determined individually for each investor, and the financial instruments described herein may not be suitable for all investors. This information is not intended to provide and should not be relied upon as providing accounting, legal, regulatory or tax advice. Investors should consult with their own advisors as to these matters.</p>"
  }
]

export const COMMON_FOOTNOTES = "Nothing on this Website is intended as an offer, advertisement, recommendation or solicitation for the purchase or sale of any particular financial instrument but rather constitutes generic, non-product specific information relating to certain live indices created by J.P. Morgan. Please refer to the index rules and related index documentation for each J.P. Morgan index described on this page. No person should make an investment decision solely on the basis of the information available on this page and prior to any investment decision a potential investor must refer to and understand the index rules and related index documentation. Any index level for a date prior to the live date (as specified in the index rules or documentation) of a J.P. Morgan index is a hypothetical back-tested level. Any backtesting information provided herein is illustrative only and derived from proprietary models based on certain data, assumptions and estimates."

export const US_INDIVIDUAL_SUMMERY_FOOTNOTES = [
  {
    header: "(1) Calculation descriptions",
    description:
      "<h3>Index Level Data</h3>\n" +
      "\n" +
      "\n" +
      "\n" +
      "<h4>Current:</h4>\n" +
      " Current refers to the closing level of the index as of the trading day immediately preceding the date you have accessed this website.<p></p>\n" +
      "\n" +
      "\n" +
      "<h4>High:</h4>\n" +
      " High refers to the highest closing level of the index during the time period selected, with the date on which such highest closing level occurs indicated under the \"Date\" column next to the High level. The High Level may be a hypothetical, historical level and not an actual level of the Index. There is no guarantee that the Index will achieve the High Level in the future.<p></p>\n" +
      "\n" +
      "\n" +
      "<h4>Low:</h4>\n" +
      " Low refers to the lowest closing level of the index during the time period selected, with the date on which such lowest closing level occurs indicated under the \"Date\" column next to the Low level. The Low Level may be a hypothetical, historical level and not an actual level of the Index. The level of the Index could, in the future, decline below the Low Level displayed on this website.<p></p>\n" +
      "\n" +
      "\n" +
      "<h4>Start:</h4>\n" +
      " Start refers to the closing level of the index on the first trading day of the time period selected.<p></p>\n" +
      "\n" +
      "\n" +
      "<h4>End:</h4>\n" +
      " End refers to the closing level of the index on the last trading day of the time period selected.\n" +
      "\n" +
      "\n" +
      "<h4>Period Index Return:</h4>\n" +
      "Period Index Return: The trailing return of the Index calculated pursuant to the following formula: (End - Start)/Start.<br>The Period Index Return may be based on hypothetical, historical Index levels and not actual Index levels. There is no guarantee that the Index will achieve the Period Index Return you computed in the future. Past performance is not indicative of future returns.<p></p>\n" +
      "</div>"
  },
  {
    header: "(2) Short Summary of the Risks",
    description: ""
  },
  {
    header: "(3) General Disclaimers",
    description: "<p><b>For certificates of deposit:</b> The information contained on this Website is for discussion purposes only. Any information relating to performance contained in these materials is illustrative and no assurance is given that any indicative returns, performance or results, whether historical or hypothetical, will be achieved. These terms are subject to change, and JPMorgan undertakes no duty to update this information. This information shall be amended, superseded and replaced in its entirety by a subsequent term sheet, disclosure supplement and/or private placement memorandum, and the documents referred to therein. In the event any inconsistency between the information presented herein and any such term sheet, disclosure supplement and/or private placement memorandum, such term sheet, disclosure supplement and/or private placement memorandum shall govern.</p>\n" +
      "\n" +
      "<p>Investments in products linked to an Index require investors to assess several characteristics and risk factors that may not be present in other types of transactions. In reaching a determination as to the appropriateness of any proposed transaction, clients should undertake a thorough independent review of the legal, regulatory, credit, tax, accounting and economic consequences of such transaction in relation to their particular circumstances. This website contains market data from various sources other than us and our affiliates, and, accordingly, we make no representation or warranty as to the market data's accuracy or completeness. All information is subject to change without notice.</p>\n" +
      "\n" +
      "<p>J.P. Morgan indices included on this Website are live. Please refer to the index rules and related index documentation for each J.P. Morgan index described on this page for further information. No person should make an investment decision on the basis of the information available on this page. Any index level for a date prior to the live date (as specified in the index rules or documentation) of a J.P. Morgan index is a hypothetical back-tested level. Any backtesting information provided herein is illustrative only and derived from proprietary models based on certain data, assumptions and estimates.</p>"
  },
  {
    header: "IRS Circular 230 Disclosure",
    description: "<p>We and our affiliates do not provide tax advice. Accordingly, any discussion of U.S. tax matters contained herein is not intended or written to be used, and cannot be used, in connection with the promotion, marketing or recommendation by anyone unaffiliated with J.P. Morgan of any of the matters address herein or for the purpose of avoiding U.S. tax-related penalties. Investment suitability must be determined individually for each investor, and the financial instruments described herein may not be suitable for all investors. This information is not intended to provide and should not be relied upon as providing accounting, legal, regulatory or tax advice. Investors should consult with their own advisors as to these matters.</p>"
  }
]

export const COPYRIGHT_TEXT: string = 'Copyright © 2015 JPMorgan Chase & Co. All Rights Reserved';

export const LANDING_PAGE_DESCRIPTION:String = 'J.P. Morgan is a leading provider of investable indices. This website provides clients with comprehensive coverage of J.P. Morgan\'s investable indices and strategies available across asset classes and regions. The website gives access to index information and descriptions, performance data and risk/return statistics.'
