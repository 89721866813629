export const US_INDIVIDUAL = [
  { itemId: 'indices', label: 'Indices', index: 0 },
  { itemId: 'notices', label: 'Notices', index: 1 },
  { itemId: 'aboutUs', label: 'About Us', index: 2 }
]

export const US_INSTITUTIONAL = [
  { itemId: 'notices', label: 'Notices', index: 0 },
  { itemId: 'aboutUs', label: 'About Us', index: 1 },

]

export const NON_US_PRIVATE = [
  { itemId: 'indices', label: 'Indices', index: 0 },
  { itemId: 'notices', label: 'Notices', index: 1 },
  { itemId: 'aboutUs', label: 'About Us', index: 2 }
]

export const NON_US_PROFESSIONAL = [
  { itemId: 'indices', label: 'Indices', index: 0 },
  { itemId: 'notices', label: 'Notices', index: 1 },
  { itemId: 'aboutUs', label: 'About Us', index: 2 }
]
