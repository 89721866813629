import React, {useEffect, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import {
  ABOUT_US, COMPLAINTS_NON_US,
  COMPLAINTS_US_INDIVIDUAL, COMPLAINTS_US_INSTITUTIONAL, CONTACT_US_INSTITUTIONAL
} from "../../utils/TextContent";
import HtmlTextRendererComponent from "../common/HtmlTextRendererComponent";
import "./AboutUs.scss";
import {useAppSelector} from "../../store/hooks";
import {selectedProfileSelector} from "../../store/slice/indices/indices";
import {useCookies} from "react-cookie";
import {
  NON_US_PRIVATE_INVESTOR_TERMS,
  NON_US_PROFESSIONAL_INVESTOR_TERMS,
  US_INDIVIDUAL_TERMS,
  US_PROFESSIONAL_TERMS
} from "../../utils/TermsText";
import AccordionWrapper from "../common/AccordionWrapper/AccordionWrapper";
const theme = createToolkitTheme('dark');
const AboutUs = () => {
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const [cookies]  = useCookies(['selectedProfile']);
  const [currentProfile, setCurrentProfile] = useState('');
  const [panelsData, setPanelsData] = useState<any[]>([]);
  const panelNames = ['panel_about_us', 'panel_complaints'];
  useEffect(() => {
    setCurrentProfile(selectedProfile.length > 0 ? selectedProfile : cookies.selectedProfile )
  },[selectedProfile, cookies.selectedProfile]);
  const getComplaints = () => {
    switch(currentProfile) {
      case "US Individual":
        return COMPLAINTS_US_INDIVIDUAL;
        break;
      case "US Institutional":
        return COMPLAINTS_US_INSTITUTIONAL
        break;
      case "Non US Private":
        return COMPLAINTS_NON_US;
        break;
      case "Non US Professional":
        return COMPLAINTS_NON_US;
        break;
      default:
        return COMPLAINTS_NON_US;
    }
  }
  useEffect(() => {
    const panels = [
      {
        panel_about_us: {
          summary: (
            <ColumnLayout container spacing={3} className="accordion-panel-heading">
              <ColumnLayout item xs={9}>
                <h3>J.P. Morgan Investable Indices</h3>
              </ColumnLayout>
            </ColumnLayout>),
          content: (<HtmlTextRendererComponent htmlText={ABOUT_US}  purify={false}/>),
        }
      },
      {
        panel_complaints: {
          summary: (
            <ColumnLayout container spacing={3} className="accordion-panel-heading">
              <ColumnLayout item xs={9}>
                <h3>Complaints</h3>
              </ColumnLayout>
            </ColumnLayout>),
          content: (
            <>
              <ColumnLayout container className="contact-us">
                  <ColumnLayout item xs={12} className="complain-content">
                    <HtmlTextRendererComponent htmlText={getComplaints()} purify={false}/>
                  </ColumnLayout>
                {currentProfile === 'US Institutional'  ?
                  <ColumnLayout item xs={12} className="contact-content">
                    <div className="contact-heading">Contact Us</div>
                    <HtmlTextRendererComponent htmlText={CONTACT_US_INSTITUTIONAL} purify={false}/>
                  </ColumnLayout> : null}
              </ColumnLayout>

            </>
          )
        }
      }
    ]
    setPanelsData(panels)
  }, [currentProfile]);

  return (
    <>
      <ColumnLayout container className="about-us">
        <ColumnLayout item xs className="accordion-container" key={currentProfile}>
          {panelsData.length > 0 ?
            <AccordionWrapper
              panelsData={panelsData}
              expandedPanels={panelNames}
              panelsArray={panelNames}
            /> :
            null}
        </ColumnLayout>
      </ColumnLayout>
    </>
  )
}
export default AboutUs;
