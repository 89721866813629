import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IIndicesObject {
  indexName:string,
  ticker: string,
  assetClass: string,
  currency: string,
  footnotes: string,
  additionalDocuments: any[],
  hasReports:boolean
  analyticsIndex: string,
  returnType: string,
  indexDescription: string,
  simulatedReturns: string,
  liveDate:number
}

interface IQueryParams {
  refetchOnMount: boolean,
  refetchOnWindowFocus: boolean,
  staleTime: number,
  retry: number
}
interface IIndicesState {
  baseURL: string;
  showLandingPage: boolean;
  selectedProfile: string;
  selectedTab: string;
  selectedIndices: IIndicesObject[];
  reportsStartDate: string;
  reportsEndDate: string;
  indicesList: any[];
  querySettings: IQueryParams;
  selectedInternalTab: string;
  indexFromURL: string;
}

export const initialState: IIndicesState = {
  baseURL: "",
  showLandingPage: true,
  selectedProfile: "",
  selectedTab: "",
  selectedIndices: [],
  reportsStartDate: "",
  reportsEndDate: "",
  indicesList: [],
  querySettings: {refetchOnMount: false, refetchOnWindowFocus: false, staleTime: 1000 * 60 * 24, retry: 1},
  selectedInternalTab: "",
  indexFromURL: ""
};
const indicesParameters = createSlice({
  name: 'indicesParameters',
  initialState,
  reducers: {
    setBaseURL: (state, action: PayloadAction<any>) => {
      state.baseURL = action.payload;
    },
    setShowLandingPage: (state, action: PayloadAction<any>) => {
      state.showLandingPage = action.payload;
    },
    setSelectedProfile: (state, action: PayloadAction<any>) => {
      state.selectedProfile = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<any>) => {
      state.selectedTab = action.payload;
    },
    setSelectedIndices: (state, action: PayloadAction<any>) => {
      state.selectedIndices = action.payload;
    },
    setReportsStartDate: (state, action: PayloadAction<any>) => {
      state.reportsStartDate = action.payload;
    },
    setReportsEndDate: (state, action: PayloadAction<any>) => {
      state.reportsEndDate = action.payload;
    },
    setIndicesList: (state, action: PayloadAction<any>) => {
      state.indicesList = action.payload;
    },
    setSelectedInternalTab: (state, action: PayloadAction<any>) => {
      state.selectedInternalTab = action.payload;
    },
    setIndexFromURL: (state, action: PayloadAction<any>) => {
      state.indexFromURL = action.payload;
    },
    resetParameters: () => ({ ...initialState })
  }
});

// actions
export const {
  setBaseURL,
  setShowLandingPage,
  setSelectedProfile,
  setSelectedTab,
  setSelectedIndices,
  setReportsStartDate,
  setReportsEndDate,
  setIndicesList,
  setSelectedInternalTab,
  setIndexFromURL
} = indicesParameters.actions;

// selectors
const selectSelf = state => state.indicesParameters;
export const baseURLSelector = createDraftSafeSelector(selectSelf, state => state?.baseURL);
export const showLandingPageSelector = createDraftSafeSelector(selectSelf, state => state?.showLandingPage);
export const selectedProfileSelector = createDraftSafeSelector(selectSelf, state => state?.selectedProfile);
export const selectedTabSelector = createDraftSafeSelector(selectSelf, state => state?.selectedTab);
export const selectedIndicesSelector = createDraftSafeSelector(selectSelf, state => state?.selectedIndices);
export const reportsStartDateSelector = createDraftSafeSelector(selectSelf, state => state?.reportsStartDate);
export const reportsEndDateSelector = createDraftSafeSelector(selectSelf, state => state?.reportsEndDate);
export const indicesListSelector = createDraftSafeSelector(selectSelf, state => state?.indicesList);
export const querySettingsSelector = createDraftSafeSelector(selectSelf, state => state?.querySettings);
export const selectedInternalTabSelector = createDraftSafeSelector(selectSelf, state => state?.selectedInternalTab);
export const indexFromURLSelector = createDraftSafeSelector(selectSelf, state => state?.indexFromURL);
// reducer
export default indicesParameters.reducer;
