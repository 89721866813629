import React from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import AppNav from './AppNav';
import {Provider} from "react-redux";
import {store} from "./store/main"; // need to add later

const App = () => (
  <ColumnLayout container>
    <Provider store={store}>
     <AppNav />
    </Provider>
  </ColumnLayout>
);

export default App;
