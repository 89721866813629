import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slice/app';
import indicesParametersReducers from '../store/slice/indices/indices';

export const store = configureStore({
  reducer: {
    app: appReducer,
    indicesParameters: indicesParametersReducers
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
