import React from 'react';
import { Icon } from '@jpmuitk/icon';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Button } from '@jpmuitk/button';
import './ActionsCell.scss';
import {useAppSelector} from "../../../store/hooks";
import {selectedIndicesSelector, selectedProfileSelector} from "../../../store/slice/indices/indices";

interface IActionsCell {
  showTearOut?: boolean;
  showDownload?: boolean;
  documentName: string;
  documentType: string;
}
const ActionsCell = ({showTearOut = true, showDownload = false, documentName, documentType}:IActionsCell) => {
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const selectedIndices = useAppSelector(selectedIndicesSelector);
  const downloadPdf = (newTab) => {
    window.open(`${window.location.origin}/indices/content/${selectedIndices[0]?.ticker?.split(' ')[0]}/${documentName}?region=${selectedProfile.split(' ').slice(0, -1).join('_').toUpperCase()}&clientType=${selectedProfile.split(' ').pop().toUpperCase()}&documentType=${documentType}`, '_blank');
  }
  return (
    <ColumnLayout container className="actions-cell">
      {showDownload ? <ColumnLayout item  className="download-container">
        <Button variant="secondary" onClick={() => {
          downloadPdf(false);
        }}><Icon name="download"/></Button>
      </ColumnLayout> : null}
      {showTearOut ? <ColumnLayout item className="tear-out-container">
        <Button variant="secondary" onClick={() => {
          downloadPdf(true);
        }}><Icon name="tear-out" size={16} aria-label="Click to open document in new tab"/></Button>
      </ColumnLayout> : null}
    </ColumnLayout>
  )
};

export default ActionsCell;
