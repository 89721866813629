import React from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import './ReturnStatistics.scss'
import Metrics from "../../../common/Metrics/Metrics";
import {Moment} from "moment/moment";
import {Icon} from "@jpmuitk/icon";


interface IselectedDates {
  startDate: Moment,
  endDate: Moment
}
interface IReturnStatisticsParams {
  returnStatistics: any[],
  selectedDates: IselectedDates
}
const ReturnStatistics = ({returnStatistics, selectedDates} : IReturnStatisticsParams) => {

  return (
    <ColumnLayout container spacing={3} className="return-statistics return-statistic-header" >
      <ColumnLayout item xs={8} className="return-statistic-header">
        Return Statistics<span className='sub-header'>{`Showing ${selectedDates?.startDate?.format('DD MMM YYYY')} - ${selectedDates?.endDate?.format('DD MMM YYYY')}`}</span>
      </ColumnLayout>
      <ColumnLayout item xs={4} className='return-statistics-info'>
       <span > <Icon name="info" style={{ marginRight: 5 }} className="document-icon" />Past performance is not a reliable indicator of future results</span>
      </ColumnLayout>
      <ColumnLayout item className="documents-container" xs={3}>
        <Metrics
          metricsData={[...returnStatistics].slice(0, 4)}
          columns={1}
          column_1_Class="level-column-1"
          column_2_Class="level-column-2"
          headerClass="level-metric-header"
          negativeClass="-dark"
        />
      </ColumnLayout>
      <ColumnLayout item className="documents-container" xs={3}>
        <Metrics
          metricsData={[...returnStatistics].slice(4, 8)}
          columns={1}
          column_1_Class="level-column-1"
          column_2_Class="level-column-2"
          headerClass="level-metric-header"
          negativeClass="-dark"
        />
      </ColumnLayout>
      <ColumnLayout item className="documents-container" xs={3}>
        <Metrics
          metricsData={[...returnStatistics].slice(8, 12)}
          columns={1}
          column_1_Class="level-column-1"
          column_2_Class="level-column-2"
          headerClass="level-metric-header"
          negativeClass="-dark"
        />
      </ColumnLayout>
      <ColumnLayout item className="documents-container" xs={3}>
        <Metrics
          metricsData={[...returnStatistics].slice(12, 15)}
          columns={1}
          column_1_Class="level-column-1"
          column_2_Class="level-column-2"
          headerClass="level-metric-header"
          negativeClass="-dark"
        />
      </ColumnLayout>
    </ColumnLayout>
  );
}

export default ReturnStatistics;
