import React, {useEffect, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Button } from '@jpmuitk/button';
import './ProfileSelector.scss';
import DialogBox from "../../common/DialogBox/DialogBox";
import {
  NON_US_PRIVATE_INVESTOR_TERMS,
  NON_US_PROFESSIONAL_INVESTOR_TERMS,
  US_INDIVIDUAL_TERMS,
  US_PROFESSIONAL_TERMS
} from "../../../utils/TermsText";
import {useAppDispatch} from "../../../store/hooks";
import {setSelectedProfile, setSelectedTab} from "../../../store/slice/indices/indices";
import { useHistory } from 'react-router-dom';
import {LANDING_PAGE_DESCRIPTION} from "../../../utils/TextContent";
const ProfileSelector = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState('');
  const [termsText, setTermsText] = useState('');
  const dispatch = useAppDispatch();
  const ShowTerms = val => {
    setOpen(true);
    setProfile(val);
  }
  const CloseTerms = () => {
    setOpen(false);
  }
  const onAcceptTerms = () => {
    dispatch(setSelectedProfile(profile));
    switch(profile) {
      case "US Individual":
        history.push('/indices');
        dispatch(setSelectedTab('Indices'));
        break;
      case "US Institutional":
        history.push('/aboutUs');
        dispatch(setSelectedTab('About Us'));
        break;
      case "Non US Private":
        history.push('/indices');
        dispatch(setSelectedTab('Indices'));
        break;
      case "Non US Professional":
        history.push('/indices');
        dispatch(setSelectedTab('Indices'));
        break;
    }
  }
  useEffect(() => {
    switch(profile) {
      case "US Individual":
        setTermsText(US_INDIVIDUAL_TERMS);
        break;
      case "US Institutional":
        setTermsText(US_PROFESSIONAL_TERMS);
        break;
      case "Non US Private":
        setTermsText(NON_US_PRIVATE_INVESTOR_TERMS);
        break;
      case "Non US Professional":
        setTermsText(NON_US_PROFESSIONAL_INVESTOR_TERMS);
        break;
    }
  }, [profile])

  return (
    <ColumnLayout container spacing={3} className='profile-selector' justify='flex-end'>
      <DialogBox onAccept={onAcceptTerms} onDecline={CloseTerms} openDialog={open} hideTerms={CloseTerms} dialogTitle={'<b>J.P. Morgan Investable Indices</b>'} dialogContent={termsText}/>
          <div className='profile-selection-container' >
            <div id='landing-text-content' >
              {LANDING_PAGE_DESCRIPTION}
            </div>
            <div className='profile-selection'>Select Profile</div>
            <div>
              US
            </div>
            <div>
              <Button variant="cta" onClick={() => {
                ShowTerms("US Individual");
              }} className="profile-button-style" id="Individual" >Individual </Button>
              <Button variant="cta" style={{marginLeft: '10px'}} onClick={() => {
                ShowTerms("US Institutional");
              }} className="profile-button-style" id="Institutional">Institutional</Button>
            </div>
            <div style={{marginTop: '30px'}} >
              Non-US
            </div>
            <div>
              <Button variant="cta" onClick={() => {
                ShowTerms("Non US Professional");
              }} className="profile-button-style" id="Professional">Investor</Button>
            </div>
          </div>
    </ColumnLayout>
  )
}

export default ProfileSelector;
