import React, {useState, useEffect} from 'react';
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import { setShowLandingPage } from '../../../store/slice/indices/indices';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@jpmuitk/dialog';
import HtmlTextRendererComponent from "../HtmlTextRendererComponent";
import {useAppDispatch} from "../../../store/hooks";

interface DialogBoxProps {
  openDialog: boolean,
  hideTerms: () => void,
  dialogTitle: string,
  dialogContent?: string;
  onAccept?: () => void,
  onDecline: () => void,
  showOkButton?: boolean;
}

const DialogBox = ({openDialog, hideTerms, dialogTitle, dialogContent='', onAccept, onDecline, showOkButton = false}:DialogBoxProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const closeDialog = val => {
    setOpen(false);
    setAccepted(val);
    hideTerms && hideTerms();
  }
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(()=> {
    dispatch(setShowLandingPage(!accepted));
    if(accepted){
      onAccept && onAccept();
    } else {
      onDecline();
    }
  },[accepted])

return (
  <>
    <Dialog
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle onClose={() => {closeDialog(false)}}><HtmlTextRendererComponent htmlText={dialogTitle}/></DialogTitle>
      <DialogContent><HtmlTextRendererComponent htmlText={dialogContent} purify={false}/></DialogContent>
      {showOkButton ? <DialogActions>
        <ButtonBar>
          <OrderedButton onClick={() => {closeDialog(false)}} variant="cta">
            Ok
          </OrderedButton>
        </ButtonBar>
      </DialogActions> : <DialogActions>
        <ButtonBar>
          <OrderedButton onClick={() => {
            closeDialog(true)
          }} variant="cta">
            Accept
          </OrderedButton>
          <OrderedButton onClick={() => {
            closeDialog(false)
          }}>Decline</OrderedButton>
        </ButtonBar>
      </DialogActions>}
    </Dialog>
  </>
)
}
export default DialogBox;
