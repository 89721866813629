import React from 'react';
import { Search } from '@jpmuitk/search';
import { FormField } from '@jpmuitk/form-field';

interface IGridSearch {
  type: string;
  filter: (val, type) => void;
  searchLabel?: string;
  searchId?: string;
  helperText?: string;
}
const GridSearch = ({filter, searchLabel, searchId, type, helperText=""}: IGridSearch) => {
  const searchHandler = (event) => {
    filter && filter(event.target.value, type);
  }
 return(
   <>
     <FormField label={searchLabel ? searchLabel : ""} variant="transparent" labelPlacement="left" className="indices-grid-search" helperText={helperText}>
       <Search autoComplete="off" onChange={searchHandler} id={searchId ? searchId : 'search-in-grid'}/>
     </FormField>
  </>
 );
};

export default GridSearch;
