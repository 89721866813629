import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Button } from '@jpmuitk/button';
import { Icon } from '@jpmuitk/icon'
import { ToggleButton, ToggleButtonGroup } from '@jpmuitk/toggle-button';
import './IndicesListing.scss';
import GridSearch from "../../common/GridSearch/GridSearch";
import { DataGrid } from '@jpmuitk/data-grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getFeaturedGridColumnDefs } from '../ColumnDefs/FeaturedGridColumnDefs';
import Footnotes from "../Footnotes/Footnotes";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
  indexFromURLSelector,
  querySettingsSelector,
  selectedProfileSelector, setIndexFromURL,
  setIndicesList,
  setSelectedIndices
} from "../../../store/slice/indices/indices";
import HtmlTextRendererComponent from "../../common/HtmlTextRendererComponent";
import {COMMON_FOOTNOTES} from "../../../utils/TextContent";
import Loader from "../../common/Loader/Loader";
import {getIndicesQuery} from "../../../utils/Utils";
import {useCookies} from "react-cookie";
import { INDICES_LISTING_FOOTNOTES } from '../../../utils/TextContent';
import {useHistory} from "react-router-dom";

const IndicesListing = () => {
  const [gridApi, setGridApi] = useState<any>(null);
  const querySettings = useAppSelector(querySettingsSelector);
  const [featuredIndicesData, setFeaturedIndicesData] = useState([]);
  const [allIndicesData, setAllIndicesData] = useState([]);
  const [selectedGrid, setSelectedGrid] = useState('featured');
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const indexFromURL = useAppSelector(indexFromURLSelector);
  const [cookies]  = useCookies(['selectedProfile']);
  const history = useHistory();
  const {data, isFetching, refetch:reFetchIndicesList} = useQuery(
    ["Indices"],
    getIndicesQuery(selectedProfile.length > 0 ? selectedProfile : cookies.selectedProfile),
    {enabled: false},
    querySettings);
  const dispatch = useAppDispatch();
  const excelParams = {
    fileName: 'Indices List'
  }
  useEffect(() => {
    if(selectedProfile?.length > 0 || cookies?.selectedProfile?.length > 0) {
      reFetchIndicesList();
    }
  },[selectedProfile, cookies?.selectedProfile]);

  const onGridReady = ({ api }) => {
    setTimeout(() => {
      api?.sizeColumnsToFit();
      api?.setGridAriaProperty("label", 'Click on index name or ticker cell of the grid to open the detail section for the index of that row');
    });
    setGridApi(api);
    window.onresize = () => {
      if(api) {
        api.sizeColumnsToFit();
      }
    }
  };
  const exportToCsv = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv(excelParams);
    }
  };
  const onGridChange = index => {
    if (index === 0 && selectedProfile === 'US Individual') {
      setSelectedGrid('featured');
    } else {
      setSelectedGrid('all');
    }
    resizeGrid();
  }
  useEffect(() => {
    if(featuredIndicesData.length === 0) {
      setSelectedGrid('all');
    } else {
      setSelectedGrid('featured');
    }
  }, [featuredIndicesData])
  const getFeaturedData = data => {
    const featuredData = [];
    data.map(value => {
      if(value.featured) {
        // @ts-ignore
        featuredData.push(value);
      }
    })
    return featuredData;
  }
  const loadListing = data => {
    dispatch(setIndexFromURL(''));
    if (selectedProfile === 'US Individual') {
      setFeaturedIndicesData(getFeaturedData(data));
      setAllIndicesData(data);
    } else {
      setAllIndicesData(data);
    }
    resizeGrid();
  }
  useEffect(() => {
    if(data) {
      dispatch(setIndicesList(data));
      if(indexFromURL.length > 0) {
        let selectedIndexData;
        data?.map(value => {
          if (value.ticker.indexOf(indexFromURL) !== -1) {
            selectedIndexData = value;
          }
        })
        if (selectedIndexData) {
          dispatch(setIndexFromURL(''));
          loadSummarySection(selectedIndexData);
        }else {
          loadListing(data);
        }
      } else {
        loadListing(data);
      }

    }
  }, [data, indexFromURL])

  const resizeGrid = () => {
    setTimeout(() => {
      gridApi?.sizeColumnsToFit();
    }, 200);
  }

  const searchInGrid = (val, type) => {
    gridApi.setQuickFilter(val);
  }

  const loadSummarySection = selectedIndexData => {
    dispatch(setSelectedIndices([{
      indexName: selectedIndexData?.name,
      ticker: selectedIndexData?.ticker,
      assetClass: selectedIndexData?.assetClass,
      currency: selectedIndexData?.ccy,
      footnotes: selectedIndexData?.footnotes,
      additionalDocuments: selectedIndexData?.additionalDocuments,
      hasReports: selectedIndexData?.hasReports,
      analyticsIndex: selectedIndexData?.analyticsRiskFreeIndex,
      returnType: selectedIndexData?.returnType,
      indexDescription: selectedIndexData?.indexDescription,
      simulatedReturns: selectedIndexData?.simulatedReturns,
      liveDate: selectedIndexData?.liveDate
    }]));
    history.push(`indices/${selectedIndexData?.ticker.split(" ")[0]}`);
  }
  const cellClickHandler = (event) => {
    if ((event?.colDef?.headerName === "Name" || event?.colDef?.headerName === 'Ticker') && (event.event.key === "Enter" || event.type === "cellClicked")) {
      setTimeout(() => {
        loadSummarySection(event?.data);
      });
    }

  };
  return (
    <>
      {isFetching ? <Loader/> :
        <ColumnLayout container spacing={3} className="indices-listing">
        <ColumnLayout item className="filter-elements">
          <GridSearch type="ticker" filter={searchInGrid} searchLabel={"Search in Grid"} searchId="asset-class-search" helperText="Type ticker or index name to search in below grid"/>
        </ColumnLayout>
        {selectedProfile === "US Individual" && allIndicesData.length > 0 ? <ColumnLayout item className="toggle-buttons">
          <ToggleButtonGroup onChange={onGridChange} defaultSelectedIndex={featuredIndicesData?.length > 0 ? 0 : 1}>
            <ToggleButton ariaLabel="featured indices" tooltipText="featured indices">
              {`FEATURED (${featuredIndicesData?.length})`}
            </ToggleButton>
            <ToggleButton ariaLabel="all indices" tooltipText="all indices">
              {`ALL (${allIndicesData?.length})`}
            </ToggleButton>
          </ToggleButtonGroup>
        </ColumnLayout> : null}
        {selectedGrid === 'featured' && selectedProfile === 'US Individual' ? null :
          <ColumnLayout container xs justify='flex-end'>
            <Button onClick={exportToCsv}><Icon name="download" className="download-icon"/>DOWNLOAD ALL</Button>
          </ColumnLayout> }
          <ColumnLayout item className="indices-grid">
            <DataGrid
              columnDefs={getFeaturedGridColumnDefs(selectedProfile === 'US Individual' || selectedProfile === 'US Institutional' ? 'US' : 'NON US')}
              rowData={selectedGrid === 'featured' && selectedProfile === 'US Individual' ? featuredIndicesData : allIndicesData}
              rowHeight={36}
              domLayout={'autoHeight'}
              onGridReady={onGridReady}
              onCellClicked={cellClickHandler}
              onCellKeyDown={cellClickHandler}
            />
          </ColumnLayout>
        <ColumnLayout item xs={1} className="footnotes-heading">Footnotes</ColumnLayout>
        {selectedProfile === "US Individual" ? <ColumnLayout item xs={11} className="footnotes-container">
          <Footnotes changeHandler={resizeGrid} footnote_data={INDICES_LISTING_FOOTNOTES}/>
        </ColumnLayout> : <ColumnLayout item xs={11} className="common-footnotes-container"><HtmlTextRendererComponent
          htmlText={COMMON_FOOTNOTES}/></ColumnLayout>}

      </ColumnLayout>}
    </>
  )
};

export default IndicesListing;
