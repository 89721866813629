import React, {memo, useState} from 'react';
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { FormField } from '@jpmuitk/form-field';
import { ListItemBase, Highlighter, useListItem } from '@jpmuitk/list';
import { ComboBox } from '@jpmuitk/combo-box';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
  indicesListSelector,
  selectedProfileSelector,
  setSelectedIndices,
  setSelectedTab
} from "../../../store/slice/indices/indices";
import { Tooltip } from '@jpmuitk/tooltip';
import {useHistory} from "react-router-dom";

const GlobalIndexSearch = () => {
  const listData = useAppSelector(indicesListSelector);
  const [searchText, setSearchText] = useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const MemoizedItem = memo(function MemoizedItem({
// @ts-ignore
  item: { name, ticker },
// @ts-ignore
  itemTextHighlightPattern,
  ...restProps
}) {
    const indexName = name;
    return (
      <ListItemBase {...restProps} style={{borderBottom: '1px solid #74777F', padding: '5px 0px'}}>
        <div>
          <div style={{ marginLeft: '8px', marginBottom: '2px',fontSize: '11px' }}>
            <b>Ticker:</b>&nbsp;&nbsp;<Highlighter matchPattern={itemTextHighlightPattern} text={ticker ? ticker : ''} />
          </div>
          <Tooltip placement="right" title={indexName}>
            <div style={{ width: '280px', textOverflow: 'ellipsis', marginLeft: '8px', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '11px' }}>
              <b>Name:</b>&nbsp;&nbsp;<Highlighter matchPattern={itemTextHighlightPattern} text={indexName ? indexName : ''} />
            </div>
          </Tooltip>
        </div>
      </ListItemBase>
    );
  });

  const ListItem = (props) => {
    const { item, itemProps } = useListItem(props);
    // @ts-ignore
    return <MemoizedItem item={item} {...itemProps} />;
  };

  const itemToString = (prop) => {
    const indexName = prop.name;
    return prop.ticker+ ' ' + indexName;
  };

  const handleSelect = (e, selectedItem) => {
    if(e.type === 'blur') {
      return false;
    }
    if (history.location.pathname.split('/').pop() === 'indices' || history.location.pathname.indexOf('indices') === -1) {
      history.push(`indices/${selectedItem?.ticker.split(" ")[0]}`);
    } else {
      history.push(`${selectedItem?.ticker.split(" ")[0]}`);
    }

    dispatch(setSelectedTab('Indices'));
    setSearchText('');
    if (selectedItem) {
      dispatch(setSelectedIndices([{
        indexName:selectedItem?.name,
        ticker: selectedItem?.ticker,
        assetClass:selectedItem?.assetClass,
        currency: selectedItem?.ccy,
        footnotes: selectedItem?.footnotes,
        additionalDocuments: selectedItem?.additionalDocuments,
        hasReports: selectedItem?.hasReports,
        analyticsIndex: selectedItem?.analyticsRiskFreeIndex,
        returnType: selectedItem?.returnType,
        indexDescription: selectedItem?.indexDescription,
        simulatedReturns: selectedItem?.simulatedReturns,
        liveDate: selectedItem?.liveDate
      }]));
    }
  };

  const getSearchText = event => {
    if (event?.target?.value) {
      setSearchText(event.target.value);
    } else {
      setSearchText('');
    }
  };


  return (
    <AriaAnnouncerProvider>

      {  // @ts-ignore
        selectedProfile !== "US Institutional" ?
        < FormField label="Search Strategy" style={{maxWidth: 300}}  labelPlacement="left" variant='transparent'>
          <ComboBox
          // @ts-ignore
          ListItem={ListItem}
          displayedItemCount={5}
          itemToString={itemToString}
          onSelect={handleSelect}
          source={listData}
          listWidth={330}
          onInputChange={getSearchText}
          inputValue={searchText}
          allowFreeText
          onInputBlur={() => {setSearchText('')}}
          />
        </FormField> : null }
    </AriaAnnouncerProvider>
)};

export default GlobalIndexSearch;
