import React from 'react';
import { Button } from "@jpmuitk/button";
import { ToolbarButton } from "@jpmuitk/toolbar";
import {openInNewTab} from "../../utils/Utils";
const PrivacyLink = () => {
  return (
    <ToolbarButton className="toolbar-footer-button" onItemClick={() => openInNewTab("https://www.jpmorgan.com/privacy")} aria-label="Opens link in new tab">
      Privacy & Security
    </ToolbarButton>
  )
}
export default PrivacyLink;
