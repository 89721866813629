import React from 'react';
import { Button } from "@jpmuitk/button";
import { ToolbarButton } from "@jpmuitk/toolbar";
import {openInNewTab} from "../../utils/Utils";
const CookiesPolicy = () => {
  return (
    <ToolbarButton className="toolbar-footer-button" onItemClick={() => openInNewTab("https://www.jpmorgan.com/cookies")} aria-label="Opens link in new tab">
      Cookies Policy
    </ToolbarButton>
  )
}
export default CookiesPolicy;
