import moment from "moment";
import FileTypeCell from "../../common/FileTypeCell/FileTypeCell";
import ActionsCell from "../../common/ActionsCell/ActionsCell";

const ReportsGridColumnDefs = [
  {
    headerName: 'Type',
    headerClass: ['grid-header'],
    field: 'documentType',
    maxWidth: 300,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Name',
    headerClass: ['grid-header'],
    field: 'documentName',
    minWidth: 300
  },
  {
    headerName: 'Date',
    headerClass: ['grid-header'],
    field: 'displayDate',
    valueGetter: params => {
      return params?.data?.displayDate === null || params?.data?.displayDate == undefined ? "-" : moment(params?.data?.displayDate).format("DD MMM YYYY");
    },
    maxWidth: 100
  },
  {
    headerName: 'File Type',
    headerClass: ['grid-header'],
    field: 'fileFormat',
    cellRendererFramework: FileTypeCell,
    cellRendererParams: params => {
      return {
        showIcon: true,
        iconType: params.data?.fileFormat === null ? 'xlsx' : params.data?.fileFormat
      }
    },
    maxWidth: 80
  },
  {
    headerName: 'Action',
    headerClass: ['grid-header'],
    field: 'fileFormat',
    cellRendererFramework: ActionsCell,
    cellRendererParams: params => {
      return {
        showTearOut: params?.data?.fileFormat !== 'pdf' ? false : true,
        showDownload: params?.data?.fileFormat === 'pdf' ? false : true,
        documentName: params?.data?.documentName,
        documentType: 'REPORT',
        fileFormat: params?.data?.fileFormat === null ? 'xlsx' : params?.data?.fileFormat
      }
    },
    maxWidth: 70
  }
]

export default ReportsGridColumnDefs
