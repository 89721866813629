enum DatePeriod {
  D1 = 'D1',
  MTD = 'MTD',
  M1 = 'M1',
  M3 = 'M3',
  QTD = 'QTD',
  M6 = 'M6',
  YTD = 'YTD',
  Y1 = 'Y1',
  Y2 = 'Y2',
  Y3 = 'Y3',
  Y5 = 'Y5',
  MAX = 'MAX'
}

export const datePeriodLabelMap = {
  [DatePeriod.D1]: '1D',
  [DatePeriod.QTD]: 'QTD',
  [DatePeriod.M6]: '6M',
  [DatePeriod.MTD]: 'MTD',
  [DatePeriod.M1]: '1M',
  [DatePeriod.M3]: '3M',
  [DatePeriod.YTD]: 'YTD',
  [DatePeriod.Y1]: '1Y',
  [DatePeriod.Y3]: '3Y',
  [DatePeriod.Y2]: '2Y',
  [DatePeriod.Y5]: '5Y',
  [DatePeriod.MAX]: 'MAX'
};

export default DatePeriod;
