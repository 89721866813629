import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { DataGrid } from '@jpmuitk/data-grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useAppSelector } from "../../../../../store/hooks";
import {
  querySettingsSelector,
  selectedProfileSelector
} from "../../../../../store/slice/indices/indices";
import {useCookies} from "react-cookie";
import DocumentsGridColumnDefs from "./DocumentsColumnDefs/DocumentsGridColumnDefs";
import {getDocumentsQuery} from "../../../../../utils/Utils";
import {selectedIndicesSelector} from "../../../../../store/slice/indices/indices";
import Loader from "../../../../common/Loader/Loader";
import './Documents.scss';

const Documents = () => {
  const [gridApi, setGridApi] = useState<any>(null);
  const querySettings = useAppSelector(querySettingsSelector);
  const [documentsData, setDocumentsData] = useState([]);
  const selectedProfile = useAppSelector(selectedProfileSelector);
  const [cookies]  = useCookies(['selectedProfile']);
  const selectedIndices = useAppSelector(selectedIndicesSelector)[0];
  const indexId = selectedIndices?.ticker?.split(" ")[0];
  const {data:docData, isFetching:isDocumentsFetching} = useQuery(["Documents", selectedIndices?.ticker?.split(" ")[0]], getDocumentsQuery(selectedProfile.length > 0 ? selectedProfile : cookies.selectedProfile, indexId), querySettings);
  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
    window.onresize = () => {
      if(api) {
        api.sizeColumnsToFit();
      }
    }
  };
  const cellClickHandler = (event) => {
    if (event?.colDef?.headerName === 'Action' && event.event.key === "Enter") {
      setTimeout(() => {
        window.open(`${window.location.origin}/indices/content/${selectedIndices[0]?.ticker?.split(' ')[0]}/${event?.data?.documentName}?region=${selectedProfile.split(' ').slice(0, -1).join('_').toUpperCase()}&clientType=${selectedProfile.split(' ').pop().toUpperCase()}&documentType=DOCUMENT`, '_blank');
      });
    }
  };
  useEffect(() => {
    if(docData) {
      setDocumentsData(docData);
    }
  }, [docData]);

  return (
    <>
      {isDocumentsFetching ? <Loader/> : <ColumnLayout container spacing={3} className="documents">
        <ColumnLayout item className="documents-grid">
          {documentsData?.length > 0 ? <h3>Documents</h3> : null}
          {documentsData?.length > 0 ? <div className="documents-grid-container" style={{height: `${36 * documentsData.length + 36}px`}}>
            <DataGrid
              columnDefs={DocumentsGridColumnDefs}
              rowData={documentsData}
              rowHeight={36}
              domLayout={'autoHeight'}
              onGridReady={onGridReady}
              onCellKeyDown={cellClickHandler}
            />
          </div>: null }
        </ColumnLayout>
      </ColumnLayout>}
    </>
  )
};

export default Documents;
