import React from 'react';
import { AppHeaderNext } from '@jpmuitk/app-header';
import { ColumnLayout } from '@jpmuitk/column-layout';
import "./LandingHeader.scss";
import {useAppSelector} from "../../store/hooks";
import {selectedProfileSelector} from "../../store/slice/indices/indices";
const LandingHeader = () => {
  const selectedProfile = useAppSelector(selectedProfileSelector);
  return (
    <ColumnLayout container className="landing-header">
      <ColumnLayout item xs={6}>
        <AppHeaderNext
          data-testid="landing-header"
          density="touch"
          className="top-app-header"
          logoProps={{href:"./"}}
        />
      </ColumnLayout>
      <ColumnLayout item xs={6} className="selected-profile-display-container">
        <ColumnLayout container className="landing-header" justify={'flex-end'}>
          <ColumnLayout item className="selected-profile-display-text">
            <span tabIndex={selectedProfile?.length > 0 ?  0 : -1}>{selectedProfile.indexOf('Non US') !== -1 ? 'Non Us Investor' : selectedProfile}</span>
          </ColumnLayout>
        </ColumnLayout>
      </ColumnLayout>
    </ColumnLayout>

  );
};

export default LandingHeader;
