import React from 'react';
import { RangePickerPanel } from '@jpmuitk/date-picker';
import DatePeriod from '../models/DatePeriod';
import { DateRangeState } from './useDateRangeState';

export const CustomRenderPanelForDateRangePicker =
  (dateRangeState: DateRangeState | undefined, exclude: string[] = []) =>
  props =>
    (
      <RangePickerPanel
        ListProps={{
          source: [
            {
              label: 'MTD',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.MTD],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: '1M',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.M1],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: '3M',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.M3],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: 'YTD',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.YTD],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: '1Y',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.Y1],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: '3Y',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.Y3],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: '5Y',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.Y5],
                endDate: dateRangeState?.endDate
              })
            },
            {
              label: 'MAX',
              getDateRange: () => ({
                startDate: dateRangeState?.[DatePeriod.MAX],
                endDate: dateRangeState?.endDate
              })
            }
          ].filter(source => !exclude.includes(source.label))
        }}
        {...props}
        dateFormat="DD MMM YYYY"
      />
    );
