import React, {useState} from 'react';
import { ToolbarButton } from "@jpmuitk/toolbar";
import DialogBox from "../common/DialogBox/DialogBox";
import {GENERAL_TERMS} from "../../utils/TermsText";
const TermsOfUse = () => {
  const [open, setOpen] = useState(false);
  const ShowTerms = val => {
    setOpen(!open);
  }
  const CloseTerms = () => {
    setOpen(false);
  }
  return (
    <ToolbarButton className="toolbar-footer-button" aria-label="Opens terms and conditions popup" onItemClick={() => {
      ShowTerms("Non US Professional");
    }}>
      <DialogBox onDecline={CloseTerms} openDialog={open} hideTerms={CloseTerms} dialogTitle={'<b>J.P. Morgan Investable Indices</b>'} dialogContent={GENERAL_TERMS} showOkButton/>
        Terms of Use
    </ToolbarButton>
  )
}
export default TermsOfUse;
