import moment from "moment";

export const getFeaturedGridColumnDefs = (profile) => {

 const FeaturedGridColumnDefs = [
    {
      headerName: 'Ticker',
      headerClass: ['grid-header'],
      field: 'ticker',
      valueGetter: params => {
        return params.data.ticker === null ? '-' : params.data.ticker;
      },
      maxWidth: 200,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Name',
      headerClass: ['grid-header'],
      field: 'name',
      valueGetter: params => {
        return params.data.name === null ? '-' : params.data.name;
      },
      minWidth: 600
    },
    {
      headerName: 'Asset Class',
      headerClass: ['grid-header'],
      field: 'assetClass',
      maxWidth: 200,
      valueGetter: params => {
        return params.data.assetClass === null ? '-' : params.data.assetClass;
      },
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'CCY',
      headerClass: ['grid-header'],
      field: 'ccy',
      valueGetter: params => {
        return params.data.ccy === null ? '-' : params.data.ccy;
      },
      maxWidth: 150
    },
    {
      headerName: 'Date',
      headerClass: ['grid-header'],
      field: 'date',
      valueGetter: params => {
        return params.data.date === null ? '-' : profile === 'US' ? moment(params.data.date).format("MMM DD, YY") : moment(params.data.date).format("DD MMM YYYY");
      },
      maxWidth: 150
    },
    {
      headerName: 'Level',
      headerClass: ['grid-header', 'level-header'],
      cellClass: ['level-color'],
      field: 'level',
      valueGetter: params => {
        return params.data.level === null ? '-' : params.data.level;
      },
      maxWidth: 150
    },
  ]

  return FeaturedGridColumnDefs;

}


