import React from 'react';
import { Spinner } from '@jpmuitk/spinner';
import { ColumnLayout } from '@jpmuitk/column-layout';

interface LoaderProps {
  message?: string;
  showSpinner?: boolean;
  width?: number | null;
  margin?: string;
}

interface AdditionalStyle {
  width?: number;
  margin?: string;
}

const Loader = ({ message = 'Loading', showSpinner = true, width = null, margin = '40px' }: LoaderProps) => {
  const additionalStyle: AdditionalStyle = {};
  if (width) {
    additionalStyle.width = width;
  }
  if (margin) {
    additionalStyle.margin = margin;
  }

  return (
    <ColumnLayout data-testid="loader" container direction="row" justify="center" alignItems="center">
      <ColumnLayout item style={additionalStyle}>
        {showSpinner && <Spinner />}
      </ColumnLayout>
    </ColumnLayout>
  );
};

export default Loader;
