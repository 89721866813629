import React from 'react';
import { Button } from "@jpmuitk/button";
import { ToolbarButton } from "@jpmuitk/toolbar";
import { useAppDispatch } from "../../store/hooks";
import {
  setShowLandingPage,
  setSelectedProfile,
  setSelectedIndices,
  setIndexFromURL
} from "../../store/slice/indices/indices";
import { useHistory } from 'react-router-dom';
const ExitProfile = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onExit = () => {
    dispatch(setShowLandingPage(true));
    dispatch(setSelectedProfile(""));
    history.push('/landing');
    dispatch(setSelectedIndices([]));
    dispatch(setIndexFromURL(''));
  }
  return (
    <ToolbarButton onItemClick={onExit}>
      <span>Exit</span>
    </ToolbarButton>
  )
}
export default ExitProfile;
