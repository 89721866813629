import React from 'react';
import { Icon } from '@jpmuitk/icon';
import { ColumnLayout } from '@jpmuitk/column-layout';
import './FileTypeCell.scss';
interface IFileTypeCell {
  showIcon?: boolean
  iconType?: string
}
const FileTypeCell = ({showIcon = true, iconType = "pdf"}:IFileTypeCell) => {
  const icons = {
    pdf: 'pdf',
    xls: 'xls',
    xlsx: 'xls'
  }
  return (
    <ColumnLayout container className="file-type-cell">
      {showIcon ? <span> <Icon name={icons[iconType]} className="icon-container" size={17} aria-label={`File type icon: ${iconType}`}/></span> : null}
    </ColumnLayout>

    )
};

export default FileTypeCell;
